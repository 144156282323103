<template>
  <div id="newsTable">
    News
    <DataTable
      id="newsTable"
      :value="stories"
      :scrollable="true"
      scrollDirection="both"
      class="p-datatable-sm"
      ref="dt"
    >
      <Column field="title" header="Title" headerStyle="width: 50px">
        <template #body="slotProps">
          <div>
            <!-- <i class="pi pi-globe" style="font-size: 1rem"></i>&nbsp; -->
            <img :src="`https://www.google.com/s2/favicons?domain=${slotProps.data.link}`" width="16px" style="vertical-align: middle" />&nbsp;
            <a :href="slotProps.data.link" target="_blank" rel="noopener noreferrer">
              {{slotProps.data.title}}
            </a>
          </div>
        </template>
      </Column>
      <Column field="author" header="Author" headerStyle="width: 50px">
        <template #body="slotProps">
          <div>
            <i class="pi pi-user" style="font-size: 1rem"></i>&nbsp;
            {{slotProps.data.author}}
          </div>
        </template>
      </Column>

      <template #footer>
        <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
      </template>

    </DataTable>
    <ProgressBar v-show="loading" mode="indeterminate"/>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressBar from 'primevue/progressbar';

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "News",
  components: {
    Button,
    DataTable,
    Column,
    ProgressBar,
  },
  data() {
    return {
      stories: [],
      loading: true,
    };
  },
  methods: {
    async init() {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url =`${VUE_APP_AUTHENTICATED_API_URL}readNewsFeed`;
      const headers = { headers: { Authorization: token.jwtToken } };
      await axios
        .get(url, headers)
        .then((response) => {
          this.stories = response.data.data.feed;
        })
        .catch((error) => {
          console.log(error);
        });
      // Limit number of stories displayed
      this.stories = this.stories.slice(0, 50);
      this.loading=false;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped>
a {
  text-decoration: none !important;
  color: #e6e6e6;
}
a:hover {
  background: #272727;
}
.p-datatable-sm {
  font-size: 12px;
}
</style>