<template>
  <div id="coinFeedViewer" />
</template>

<script>
import {
  AxesHelper,
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Group,
  LineBasicMaterial,
  LineSegments,
  MeshBasicMaterial,
  MeshLambertMaterial,
  Object3D,
  OrthographicCamera,
  PointLight,
  PointsMaterial,
  Points,
  Scene,
  Vector3,
  WebGLRenderer,
} from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
//import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  name: "CoinFeedLogo",

  // Objects stored on the component instance
  data() {
    return {
      scene: (this.scene = new Scene()),
      renderer: new WebGLRenderer({
        antialias: true,
        alpha: true,
        preserveDrawingBuffer: true,
      }),
      camera: null,
      orbitControls: null,
      frameId: null,
      container: null,
      shadedGeometry: new Group(),
      wireframeGeometry: new Group(),
      pointGeometry: new Group(),
      logoScale: 0.07,
    };
  },

  methods: {
    // Entry point
    init: function () {
      this.container = document.getElementById("coinFeedViewer");

      // Orthographic Camera
      (this.camera = new OrthographicCamera(
        (45 * this.container.offsetWidth) / this.container.offsetHeight / -2, // left - Camera frustum left plane
        (45 * this.container.offsetWidth) / this.container.offsetHeight / 2, // right - Camera frustum right plane
        80 / 2, // top - Camera frustum top plane
        80 / -2, // bottom - Camera frustum bottom plane
        1, // near - Camera frustum near plane
        2000 // far - Camera frustum far plane
      )),
        this.camera.position.set(0, 0, 500);

      // Scene
      this.scene.add(this.camera);

      // Light
      const pointLight = new PointLight(0xffffff);
      pointLight.position.set(0, 50, 0);
      this.camera.add(pointLight);

      // Materials
      //const material = new MeshBasicMaterial({ color: this.themeColor, side: DoubleSide });
      const material = new MeshLambertMaterial({
        color: "#DADADA",
        side: DoubleSide,
      });
      const wireframeMaterial = new MeshBasicMaterial({
        color: 0xffffff,
        wireframe: true,
        opacity: 1.0,
      });
      const pointMaterial = new PointsMaterial({ size: 1.0, color: 0xdadada });

      // Geometry
      const loader = new OBJLoader();

      // Group for shaded geometry
      let shadedGeometry = this.shadedGeometry;
      shadedGeometry.name = "shadedGeometry";
      shadedGeometry.visible = true;

      // Group for wireframe geometry
      let wireframeGeometry = this.wireframeGeometry;
      wireframeGeometry.name = "wireframeGeometry";
      wireframeGeometry.visible = false;

      // Group for point geometry
      let pointGeometry = this.pointGeometry;
      pointGeometry.name = "pointGeometry";
      pointGeometry.visible = false;

      // Load text mesh
      loader.load(
        // Resource URL
        "/3dlogo.obj",

        // Called when resource is loaded
        function (object) {
          let clones = new Object3D();
          let points = new Object3D();

          object.children.forEach((child) => {
            if (child.type === "Mesh") {
              // Orient to expected world xyz
              child.rotation.x = -Math.PI / 2;

              // Clone for wireframe representation
              let clone = child.clone();
              clone.material = wireframeMaterial;
              clones.add(clone);

              let vertices = clone.geometry.attributes.position;
              let positions = vertices;
              var geometry = new BufferGeometry();
              geometry.setAttribute("position", positions);
              var pointSet = new Points(geometry, pointMaterial);
              pointSet.rotation.x = -Math.PI / 2;
              points.add(pointSet);

              child.material = material;
              child.geometry.computeVertexNormals();
              child.name = "alfarok";
            }
          });

          shadedGeometry.add(object);
          wireframeGeometry.add(clones);
          pointGeometry.add(points);
        }
      );

      let scale = this.logoScale;
      this.shadedGeometry.scale = new Vector3(scale, scale, scale);
      this.wireframeGeometry.scale = new Vector3(scale, scale, scale);
      this.pointGeometry.scale = new Vector3(scale, scale, scale);

      this.scene.add(shadedGeometry);
      this.scene.add(wireframeGeometry);
      this.scene.add(pointGeometry);

      // Build axis and grid
      //this.buildSceneHelpers();

      // Controls
      /*
      this.orbitControls = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
      */

      // Render
      this.$emit("setRendererSize", [
        this.container.offsetWidth,
        this.container.offsetHeight,
      ]);
      this.container.appendChild(this.renderer.domElement);

      // Events
      window.addEventListener("resize", this.onWindowResize, false);
      // Ensure window size is up-to-date
      window.dispatchEvent(new Event("resize"));
    },

    // Main render function
    render: function () {
      const s = -0.02;
      //this.shadedGeometry.rotation.x += s;
      this.shadedGeometry.rotation.y += s;

      /*
      this.wireframeGeometry.rotation.x += s;
      this.wireframeGeometry.rotation.y += s;

      this.pointGeometry.rotation.x += s;
      this.pointGeometry.rotation.y += s;
      */

      this.renderer.render(this.scene, this.camera);
      this.frameId = requestAnimationFrame(this.render);
    },

    // Helper function to build grid and axes helper
    buildSceneHelpers: function () {
      // Grid
      const size = 100;
      const step = 5;
      let gridHelper = new BufferGeometry();
      let gridMaterial = new LineBasicMaterial({
        color: 0x000000,
        transparent: true,
        opacity: 0.1,
      });

      let vertices = [];

      for (var i = -size; i <= size; i += step) {
        vertices.push(new Vector3(-size, 0, i));
        vertices.push(new Vector3(size, 0, i));
        vertices.push(new Vector3(i, 0, -size));
        vertices.push(new Vector3(i, 0, size));
      }

      gridHelper.setAttribute(
        "position",
        new BufferAttribute(new Float32Array(vertices), 3)
      );

      let gridLines = new LineSegments(gridHelper, gridMaterial, LineSegments);
      gridLines.name = "grid";
      this.scene.add(gridLines);

      // Axes
      let axesHelper = new AxesHelper(100);
      axesHelper.name = "axes";
      axesHelper.translateY(0.001);
      axesHelper.rotateX(-Math.PI / 2);
      this.scene.add(axesHelper);
    },

    // Attempt to remove any previously existing canvas or stats
    clearObj: function (obj) {
      while (obj.children.length > 0) {
        this.clearObj(obj.children[0]);
        obj.remove(obj.children[0]);
      }
      if (obj.geometry) obj.geometry.dispose();

      if (obj.material) {
        //in case of map, bumpMap, normalMap, envMap ...
        Object.keys(obj.material).forEach((prop) => {
          if (!obj.material[prop]) return;
          if (typeof obj.material[prop].dispose === "function")
            obj.material[prop].dispose();
        });
        obj.material.dispose();
      }
    },

    onWindowResize: function () {
      this.camera.aspect =
        this.container.offsetWidth / this.container.offsetHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.offsetWidth,
        this.container.offsetHeight
      );

      // Updating an orthographic camera
      this.camera.left =
        (80 * this.container.offsetWidth) / this.container.offsetHeight / -2;
      this.camera.right =
        (80 * this.container.offsetWidth) / this.container.offsetHeight / 2;
      this.camera.top = 80 / 2;
      this.camera.bottom = 80 / -2;
      this.camera.updateProjectionMatrix();
    },
  },

  // Function called when component is initially mounted
  mounted() {
    this.init();
    this.render();
  },

  // Dispose
  beforeDestroy: function () {
    // Unsubscribe
    window.removeEventListener("resize", this.onWindowResize, false);

    // Cancel animation loop
    cancelAnimationFrame(this.frameId);

    this.clearObj(this.scene);

    // TODO - research dispose method?
    this.camera = null;
    this.orbitControls = null;
  },
};
</script>

<style scoped>
#coinFeedViewer {
  /*width: 100vw;*/
  /*height: 30vh;*/
  /*border: 2px solid blue;*/
  width: 100%;
  height: 200px;
}
</style>