<template>
  <div id="app">
    <Home/>
  </div>
</template>

<script>
import Home from './components/Home.vue'
import './themes/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body {
  margin: 0px !important;
  padding: 0px !important;
  background: #121212;
  color: #e6e6e6;
  padding-bottom: 50px !important;
}
:root {
  --amplify-font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  --amplify-text-xxs: 0.75rem;
  --amplify-text-xs: 0.81rem;
  --amplify-text-sm: 0.875rem;
  --amplify-text-md: 1rem;
  --amplify-text-md-sub: 1.15rem;
  --amplify-text-lg: 1.5rem;
  --amplify-text-xl: 2rem;
  --amplify-text-xxl: 2.5rem;
  --amplify-primary-color: #e6e6e6;
  --amplify-primary-contrast: #212529;
  --amplify-primary-tint: #808080;
  --amplify-primary-shade: #808080;
  --amplify-secondary-color:  #e6e6e6;
  --amplify-secondary-contrast: #212529;
  --amplify-secondary-tint: #ffcdd2;
  --amplify-secondary-shade: #1F2A37;
  --amplify-tertiary-color: #5d8aff;
  --amplify-tertiary-contrast: #212529;
  --amplify-tertiary-tint: #7da1ff;
  --amplify-tertiary-shade: #537BE5;
  --amplify-background-color: rgba(0, 0, 0, 0);
  --amplify-grey: #828282;
  --amplify-light-grey: #c4c4c4;
  --amplify-white: #212529;
  --amplify-smoke-white: #f5f5f5;
  --amplify-red: #dd3f5b;
  --amplify-blue: #099ac8;
}
/* RSI Offset */
.trading-vue-grid-1 {
  margin-top: 10px !important;
}
.trading-vue-sidebar-1 {
  margin-top: 10px !important;
}
.chartLarge-sidebar-1-canvas {
  margin-top: 10px !important;
}
trading-vue-grid-1
.t-vue-lspan{
  color: #e6e6e6 !important; 
}
/* Controls selected candle toolbar icon filter color*/
.selected-item {
  filter: brightness(5.0) sepia(1) hue-rotate(180deg) saturate(0.0) !important;
}
/* Required to hide input filter on dropdowns - bug */
.p-hidden-accessible{
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/* Required to appear above .trading-vue-legend 100 z-index */
.p-menubar .p-submenu-list {
  z-index: 101;
}
/* Input switch - matching visibility for on and off */
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #383838;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider{
  background: #272727;
}
.p-progressbar {
  height: 2px;
  margin: 0 auto;
}
/* Calendar Input */
.p-inputtext {
  text-align: center;
  color: #b3b3b3;
}
/* Hide select all in multi-select dropdowns */
.p-multiselect-header > .p-checkbox{
	display: none;
}
/* Hides padding from overlay panels by default */
.p-overlaypanel .p-overlaypanel-content {
  padding: 0px !important;
}
</style>
