<template>
  <div>
    User Settings
    <br /><br />
    Top 3 Coins:
    <div class="p-flex">
      <MultiSelect
        id="topCoins"
        v-model="selectedCoins"
        :options="allCoins"
				:filter="true"
        display="chip"
      />
    </div>
		<br />
		<Button id="saveSettingsButton" icon="pi pi-save" label="Save Settings" @click="saveSetting()"/>
		<ProgressBar v-show="loading" mode="indeterminate" />
  </div>
</template>

<script>
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import ProgressBar from "primevue/progressbar";

export default {
  name: "UserSettings",
  props: ["coins", "userSettings"],
  components: {
		Button,
    MultiSelect,
		ProgressBar,
  },
  data() {
    return {
      selectedCoins: undefined,
      allCoins: [],
			loading: false,
    };
  },
  methods: {
    async init() {
      this.getUserCoinPreferences();
    },
    async getUserCoinPreferences() {
			this.loading = true;

      this.coins.forEach((coin) => {
        const formattedCoin = `${coin.symbol}`;
        this.allCoins.push(formattedCoin);
      });

      if(this.userSettings !== undefined) {
        this.selectedCoins = this.userSettings.selectedCoins;
      } else {
        this.selectedCoins = ['BTC', 'ETH', 'ADA'];
      }

			this.loading = false;
    },
		async saveSetting() {
			this.loading = true;
      if(this.selectedCoins.length !== 3){
        // TODO: throw error toast
        return;
      }
			this.$emit('updateUserSettings', {
        selectedCoins: this.selectedCoins,
      })
		}
  },
  watch: {
    selectedCoins: async function (newVal) {
      if (newVal.length > 3) {
        this.selectedCoins.shift();
      }
    },
    userSettings: function() {
      this.loading = false;
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
#topCoins {
  width: 350px;
}
#saveSettingsButton {
	width: 350px;
}
.p-progressbar {
	margin-top: 5px;
	width: 350px;
}
</style>
