<template>
  <div>
    <div id="treemap" />
    <div id="treemapOverlay">
      <OverlayPanel ref="treemapOverlay" style="color:inherit">
        <img alt="" :src="overlayIconUrl" width="50" style="vertical-align: middle" />
        <p>{{ overlayName }}</p>
        <p>${{ overlayPrice }}</p>
        <p>{{ overlayPercentage }}%</p>
      </OverlayPanel>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import router from "../router";
import OverlayPanel from "primevue/overlaypanel";

export default {
  name: "TreeMap",
  props: ["coins"],
  components: {
    OverlayPanel,
  },
  data() {
    return {
      data: {
        children: [
          {
            name: "group1",
            children: [],
            colname: "level1",
          },
          {
            name: "group2",
            children: [],
            colname: "level2",
          },
          {
            name: "group2",
            children: [],
            colname: "level2",
          },
        ],
        name: "Coins",
      },
      overlayName: "",
      overlayPrice: 0,
      overlayPercentage: 0,
      overlayIconUrl: "",
      overlayTextColor: "#e6e6e6",
    };
  },
  methods: {
    init() {
      let priceChangePercentagesUp = [];
      let priceChangePercentagesDown = [];

      for (let i = 0; i < this.coins.length; i++) {
        const change = this.coins[i].priceChangePercent;
        if (change >= 0) {
          priceChangePercentagesUp.push(change);
        } else {
          priceChangePercentagesDown.push(change);
        }
      }

      const downMin = Math.min(...priceChangePercentagesDown);
      const even = 0;
      const upMax = Math.max(...priceChangePercentagesUp);

      // Build colors interpolated between red and green with range 0-1
      let downColorInterpolator = d3.interpolateRgb("#78201e", "#ef3f3c");
      let upColorInterpolator = d3.interpolateRgb("#00b746", "#005c23");
      
      for (let i = 0; i < this.coins.length; i++) {
        let coinData = {
          index: i,
          iconUrl: this.coins[i].image,
          name: this.coins[i].symbol,
          fullName: this.coins[i].name,
          value: this.coins[i].marketCap,
          rank: this.coins[i].marketCapRank,
          priceChange: this.coins[i].priceChange,
          priceChangePercent: this.coins[i].priceChangePercent,
          lastPrice: this.coins[i].lastPrice,
        };

        if (this.coins[i].priceChangePercent >= 0) {
          coinData["color"] = upColorInterpolator(
            this.remap(coinData.priceChangePercent, even, upMax)
          );
        } else {
          coinData["color"] = downColorInterpolator(
            this.remap(coinData.priceChangePercent, downMin, even)
          );
        }

        if (this.coins[i].marketCapRank < 11) {
          coinData["group"] = 1;
          this.data.children[0].children.push(coinData);
        } else if (
          this.coins[i].marketCapRank >= 11 &&
          this.coins[i].marketCapRank < 51
        ) {
          coinData["group"] = 2;
          this.data.children[1].children.push(coinData);
        } else {
          coinData["group"] = 3;
          this.data.children[2].children.push(coinData);
        }
      }

      // Set the dimensions and margins of the graph
      const margin = { top: 0, right: 0, bottom: 0, left: 0 };
      const width = window.innerWidth - margin.left - margin.right;
      const height = window.innerHeight - 100 - margin.top - margin.bottom;

      const treemap = d3.treemap().size([width, height]);

      let div = d3
        .select("#treemap")
        .append("div")
        .style("position", "relative")
        .style("width", width + margin.left + margin.right + "px")
        .style("height", height + margin.top + margin.bottom + "px")
        .style("left", margin.left + "px")
        .style("top", margin.top + "px");

      // Give the data to the cluster layout
      const root = d3.hierarchy(this.data).sum(function (d) {
        return d.value;
      });

      const tree = treemap(root);

      var overlay = this.$refs.treemapOverlay;

      const context = this;
      function setOverlayValues(url, name, price, percentage) {
        // Update overlay details
        context.overlayIconUrl = url,
        context.overlayName = name;
        context.overlayPrice = price;
        context.overlayPercentage = percentage;
        // Update overlay text color
        if(percentage > 0) {
          context.overlayTextColor = "#00b746";
        } else if (percentage < 0) {
          context.overlayTextColor = "#ef3f3c";
        } else {
          context.overlayTextColor = "#e6e6e6";
        }
        document.getElementById("treemapOverlay").style.color = context.overlayTextColor;
      }

      div
        .datum(root)
        .selectAll(".node")
        .data(tree.leaves())
        .enter()
        .append("div")
        .attr("class", "node")
        .style("left", (d) => d.x0 + "px")
        .style("top", (d) => d.y0 + "px")
        .style("width", (d) => Math.max(0, d.x1 - d.x0 - 1) + "px")
        .style("height", (d) => Math.max(0, d.y1 - d.y0 - 1) + "px")
        .style("background", function (d) {
          return d.data.color;
        })
        .text((d) => d.data.name)
        .style("font-size", function (d) {
          const group = d.data.group;
          if (d.data.rank === 1 || d.data.rank === 2) {
            return "32px";
          } else if (group === 1) {
            return "16px";
          } else if (group === 2) {
            return "8px";
          } else {
            return "4px";
          }
        })
        .style("color", function () {
          return "#e6e6e6";
        })
        .on("click", function (error, d) {
          router.push({ path: `/coin/${d.data.name}` }).catch(() => {
            "Cannot navigate to current page";
            console.log(error);
          });
          window.scrollTo(0, 0);
        })
        .on("mouseover", function (e, d) {
          // Update hover overlay panel
          setOverlayValues(
            d.data.iconUrl,
            d.data.fullName,
            d.data.lastPrice,
            d.data.priceChangePercent
          );
          // Show
          overlay.toggle(e);
        })
        .on("mouseout", function (e) {
          // Hide
          overlay.toggle(e);
        });

      // Call the resize function whenever a resize event occurs
      d3.select(window).on("resize", this.resize);
    },
    resize() {
      // Reset
      const oldNode = document.getElementById("treemap");
      oldNode.innerHTML = "";
      this.data.children[0].children = [];
      this.data.children[1].children = [];
      this.data.children[2].children = [];
      // Reinit
      this.init();
    },
    remap(value, sourceMin, sourceMax, destMin = 0, destMax = 1) {
      const result = destMin + ((value - sourceMin) / (sourceMax - sourceMin)) * (destMax - destMin);
      return result;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.node {
  border: solid 0.5px #121212;
  font: 10px sans-serif;
  line-height: 12px;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
.node:hover {
  cursor: pointer;
  border: 2px solid rgb(100, 181, 246);
}
.p-overlaypanel {
  margin: 0px;
  padding: 1em;
}
</style>

<style scoped>
#treemap {
  margin: auto;
  position: relative;
}
</style>