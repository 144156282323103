<template>
  <div id="coinTable">
    Cryptocurrencies
    <CoinFlexTable
      :headerValues="headerValues"
      :rowValues="rowValues"
      :rowColors="rowColors"
      :heightPercentage="heightPercentage"
      @updateSortKeys="updateSortKeys"
    />
  </div>
</template>

<script>
import router from "./../router";
import CoinFlexTable from "./CoinFlexTable.vue";

export default {
  name: "Coins",
  components: {
    CoinFlexTable,
  },
  props: ["coins"],
  watch: {
    coins: {
      deep: true,
      handler: function () {
        this.update();
      },
    },
  },
  data() {
    return {
      headerValues: [
        "Coin",
        "Last",
        "Market Cap",
        "Volume",
        "Change",
        "Change%",
        "Low 24",
        "High 24",
        "Count",
        "Circ Supply",
        "Total Supply",
        "Cap 24 Change",
        "Cap 24 Change%",
        "Prev Close",
        "Open",
        "Weighted Avg",
        "Ask",
        "Bid",
        "Ask Qty",
        "Bid Qty",
        "Last Qty",
        "Quote Vol",
      ],
      rowValues: [],
      rowColors: [],
      sortKeys: [],
      heightPercentage: "80vh",
    };
  },
  methods: {
    update() {
      // Build coin table
      let newRowValues = {};
      let newKeys = [];
      let newColors = {};
      for (let i = 0; i < this.coins.length; i++) {
        newKeys.push(this.coins[i].name);
        newRowValues[this.coins[i].name] = [
          [
            this.coins[i].name,
            this.coins[i].symbol,
            this.coins[i].image,
            this.coins[i].marketCapRank,
          ],
          [this.coins[i].lastPrice],
          [this.coins[i].marketCap],
          [this.coins[i].volume],
          [this.coins[i].priceChange],
          [this.coins[i].priceChangePercent],
          [this.coins[i].lowPrice],
          [this.coins[i].highPrice],
          [this.coins[i].count],
          [this.coins[i].circulatingSupply],
          [this.coins[i].totalSupply],
          [this.coins[i].marketCapChange24h],
          [this.coins[i].marketCapChangePercentage24h],
          [this.coins[i].prevClosePrice],
          [this.coins[i].openPrice],
          [this.coins[i].weightedAvgPrice],
          [this.coins[i].askPrice],
          [this.coins[i].bidPrice],
          [this.coins[i].askQty],
          [this.coins[i].bidQty],
          [this.coins[i].lastQty],
          [this.coins[i].quoteVolume],
        ];

        let color = "#e6e6e6";
        let change = this.coins[i].priceChangePercent;

        if (parseFloat(change) > 0) {
          color = "#00b746";
        } else if (parseFloat(change) < 0) {
          color = "#ef403c";
        }

        newColors[this.coins[i].name] = color;
      }
      // Sort new values based on previous keys
      let replacementRows = [];
      let replacementColors = [];
      if (this.sortKeys.length > 0) {
        for (let i = 0; i < this.sortKeys.length; i++) {
          replacementRows.push(newRowValues[this.sortKeys[i]]);
          replacementColors.push(newColors[this.sortKeys[i]]);
        }
      } else {
        // Apply default sort
        newKeys = newKeys.sort(function (a, b) {
          const propSortIndex = 2; // market cap
          if (
            parseFloat(newRowValues[a][propSortIndex]) >
            parseFloat(newRowValues[b][propSortIndex])
          ) {
            return -1;
          } else if (
            parseFloat(newRowValues[a][propSortIndex]) <
            parseFloat(newRowValues[b][propSortIndex])
          ) {
            return 1;
          } else {
            return 0;
          }
        });

        for (let i = 0; i < newKeys.length; i++) {
          replacementRows.push(newRowValues[newKeys[i]]);
          replacementColors.push(newColors[newKeys[i]]);
        }
      }
      // Push new values to table
      this.rowValues = replacementRows;
      this.rowColors = replacementColors;
    },
    updateSortKeys(newKeys) {
      this.sortKeys = newKeys;
    },
    goToCoin(symbol) {
      router.push({ path: `/coin/${symbol}` }).catch(() => {
        "Cannot navigate to current page";
      });
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style scoped>
#coinTable {
  margin: none;
}
</style>