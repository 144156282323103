<template>
  <div>
    <marquee id="ticker" scrollamount="2"/>
    <div class="p-grid">
      <div class="p-col">
        <Candlestick :coin="userSettings.selectedCoins[0]" :selectedInterval="selectedInterval" :toggleChartType="toggleChartType"/>
      </div>
      <div class="p-col">
        <Candlestick :coin="userSettings.selectedCoins[1]" :selectedInterval="selectedInterval" :toggleChartType="toggleChartType"/>
      </div>
      <div class="p-col">
        <Candlestick :coin="userSettings.selectedCoins[2]" :selectedInterval="selectedInterval" :toggleChartType="toggleChartType"/>
      </div>
    </div>
    <div id="candleMenu">
      <span>Interval: <Dropdown v-model="selectedInterval" :options="intervalOptions" optionLabel="interval"/></span>&nbsp;
      <br v-if="windowWidth < 500"/>
      <span>Candles/Line: <InputSwitch v-model="toggleChartType"/></span>&nbsp;
      <span>Interactive: <InputSwitch v-model="toggleChartInteractions"/></span>&nbsp;
    </div>
    <br>
    <div class="p-grid">
      <div class="p-col">
        <CoinTable :coins="coins"/>
      </div>
    </div>
    <div v-if="windowWidth > 800" class="p-grid">
      <div class="p-col">
        <router-link to="/news">Headlines</router-link>
        <FrontNews/>
      </div>
    </div>
    <!-- Mobile -->
    <div v-else class="p-grid">
      <div class="p-col">
        <router-link to="/news">Headlines</router-link>
        <FrontNews/>
      </div>
    </div>
    <router-link to="/map">Coin Map</router-link>
    <TreeMap :coins="coins"/>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Candlestick from './Candlestick.vue';
import CoinTable from './CoinTable.vue';
import FrontNews from './FrontNews.vue';
import TreeMap from "./TreeMap.vue";

export default {
  name: "Authenticated",
  data() {
    return {
      windowWidth: undefined,
      intervalOptions: [
        { interval: '1m' },
        { interval: '3m' },
        { interval: '5m' },
        { interval: '15m' },
        { interval: '30m' },
        { interval: '1h' },
        { interval: '2h' },
        { interval: '4h' },
        { interval: '6h' },
        { interval: '8h' },
        { interval: '12h' },
        { interval: '1d' },
        { interval: '3d' },
        { interval: '1w' },
        { interval: '1M' },
      ],
      selectedInterval: { interval: '1m' },
      toggleChartType: false,
      toggleChartInteractions: false,
    }
  },
  components: {
    Dropdown,
    InputSwitch,
    Candlestick,
    CoinTable,
    FrontNews,
    TreeMap,
  },
  props: ["user", "coins", "userSettings", "marquee"],
  methods: {
    init() {
      document.getElementById("ticker").innerHTML = this.marquee.join("");
    },
    onResize(/*event*/) {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.onResize);
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    toggleChartInteractions: function(newVal) {
      const chartDivs = document.getElementsByClassName('frontCandleCharts');
      let styleText = '';
      if(newVal) {
        styleText = "pointer-events: all;"
      } else {
        styleText = "pointer-events: none;"
      }
      chartDivs.forEach(element => element.style = styleText);
    },
  }
};
</script>

<style scoped>
#candleMenu{
  font-size: 12px;
  padding-bottom: 4px;
  /*
  background-color: #1e1e1e;
  padding-bottom: 4px;
  */
}
.p-grid {
  margin: 0;
}
.p-col {
  padding:  0;
  margin: 0;
}
.p-inputswitch {
  top: 10px !important;
}

a {
  text-decoration: none !important;
  color: #e6e6e6;
}
</style>
