<template>
  <div>
    <CandlestickLarge v-if="coin !== undefined" :coin="coin" />
    <br>
    <img :alt="coinName" :src="coinImageLink" width="50" style="vertical-align: middle" />
    <PriceTicker v-if="coin !== undefined" :coin="coin" @setPrice="setPrice"/>
    <br>
    <div id="rangeVisualizer" class="p-grid p-ai-stretch vertical-container">
      <RangeVisualizer
        title="24 Hour"
        :range="coinRange"
        :coinPrice="coinPrice"
      />
      <RangeVisualizer
        title="All Time"
        :range="coinRange"
        :coinPrice="coinPrice"
      />
      
    </div>
    <br>
    <div id="orderBook">
      <OrderBook v-if="coin !== undefined" :coin="coin"/>
    </div>
    <br>
    <CoinData v-if="coinName !== undefined" :coinName="coinName" @setRange="setRange" @setCoinImageLink="setCoinImageLink"/>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import CandlestickLarge from "./CandlestickLarge.vue";
import CoinData from "./CoinData.vue";
import PriceTicker from "./PriceTicker.vue";
import RangeVisualizer from "./RangeVisualizer.vue";
import OrderBook from "./OrderBook.vue";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "Coin",
  components: {
    CandlestickLarge,
    CoinData,
    PriceTicker,
    RangeVisualizer,
    OrderBook,
  },
  data() {
    return {
      coin: undefined,
      coinName: undefined,
      coinPrice: undefined,
      coinRange: undefined,
      coinImageLink: undefined,
    };
  },
  methods: {
    async init() {
      // Retrieve dynamic route (coin/SYMBOL_NAME)
      this.coin = this.$route.params.symbol;
      // Lets also get the coin name from the symbol
      this.coinName = await this.getCoinName();
    },
    async getCoinName() {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url =`${VUE_APP_AUTHENTICATED_API_URL}getCoinName`;
      const headers = { headers: { Authorization: token.jwtToken } };
      const body = JSON.stringify({ symbol: this.coin });
      let result = undefined;
      await axios
        .post(url, body, headers)
        .then((response) => {
          result = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
      return result;
    },
    setRange(data) {
      this.coinRange = data;
    },
    setPrice(price) {
      this.coinPrice = price;
    },
    setCoinImageLink(imageLinks) {
      this.coinImageLink = imageLinks.large;
    },
  },
  watch: {
    $route: async function (to, /*from*/) {
      const newSymbol = to.path.split("/")[2];
      this.coin = newSymbol;
      this.coinName = await this.getCoinName();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
#rangeVisualizer {
  margin: 5px;
  text-align: left;
}
#orderBook {
  height: 200px;
}
</style>