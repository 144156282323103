import Vue from 'vue'
import Router from 'vue-router'

import components from './../components'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		// Home
		{ name: 'root', path: '/', component: components.FrontPage, props: true },
		{ name: 'home', path: '/home', component: components.FrontPage, props: true },

		// Nav Bar
		{ name: 'coins', path: '/coins', component: components.Coins, props: true },
		{ name: 'news', path: '/news', component: components.News, props: true },
		//{ name: 'exchanges', path: '/exchanges', component: components.Exchanges, props: true },
		//{ name: 'staking', path: '/staking', component: components.Template, props: true },
		//{ name: 'pro', path: '/pro', component: components.Template, props: true },

		// Tools
		//{ name: 'arbitrage', path: '/arbitrage', component: components.Arbitrage, props: true },
		//{ name: 'historicalData', path: '/historicalData', component: components.HistoricalData, props: true },
		{ name: 'coinMap', path: '/map', component: components.CoinMap, props: true },

		// Dynamic
		{ name: 'coin', path: '/coin/:symbol', component: components.Coin, props: true },
		//{ name: 'chart', path: '/chart', component: components.Chart, props: true },
		//{ name: 'chartDisplay', path: '/chart/:chartName', component: components.Chart, props: true },
		//{ name: 'whales', path: '/whales', component: components.Whales, props: true },
		//{ name: 'whalesDisplay', path: '/whales/:whaleCoin', component: components.Whales, props: true },
		//{ name: 'sentiment', path: '/sentiment', component: components.Sentiment, props: true },
		//{ name: 'sentimentDisplay', path: '/sentiment/:sentimentType', component: components.Sentiment, props: true },
		//{ name: 'mining', path: '/mining', component: components.Mining, props: true },
		//{ name: 'miningDisplay', path: '/mining/:miningType', component: components.Mining, props: true },

		// User
		{ name: 'userSettings', path: '/user/settings', component: components.UserSettings, props: true },

		// Conditional
		{
			name: 'debug', path: '/debug', get component() {
				if (process.env.VUE_APP_DEPLOYMENT_ENVIRONMENT === "development") {
					return components.Debugger;
				}
				else {
					return components.FrontPage;
				}
			},
			props: true
		},

		// Default (if invalid route entered)
		{ name: 'default', path: '*', component: components.FrontPage, props: true }
	],
})