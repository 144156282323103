<template>
  <div id="terms">
    <h1>Terms and Conditions</h1>
    <p>These Terms and Conditions constitute a binding agreement between you and any person or entity on whose behalf you are acting (collectively, “You”) and alfarok llc (“alfarok”). These Terms and Conditions govern Your use of the COINFEED® online software and the data and information contained therein, including all data and information accessible through use of the COINFEED® online software and all data and information generated by the COINFEED® online software or otherwise underlying the software and displayed or generated data (collectively, the “Services”).</p>
    
    <p>The Services include: (i) a alpha and/or beta versions of the COINFEED® online software (“Alpha and/or Beta Services”); (ii) a limited term trial version of the COINFEED® online software (“Trial Services”); and (iii) a renewable subscription to the full version of the COINFEED® online software (“Member Services”). These Terms and Conditions apply to each of the Services, with particular restrictions set forth below based on the Services You have subscribed to. By selecting “I Accept,” or by accessing or using any the Services, you agree to be bound by these Terms and Conditions. These Terms and Conditions are in addition to, and not to the exclusion of, any other written agreements between You and alfarok.</p>
    
    <h3>1. Limited License.</h3>
    
    <h3>1.1. License Grant.</h3>
    
    <p>By agreeing to these Terms and Conditions, and subject to any other written agreement between alfarok and You, alfarok grants to You a non-exclusive, non-transferable, limited, worldwide licensed to use the Services to which you have subscribed and to access COINFEED® online software under that subscription only in accordance with these Terms and Conditions (the “License”). This License is personal to You. You may not distribute or make the Services available to third parties except to the extent required by law.</p>
    
    <h3>1.2. License Term.</h3>
    
    <p>Subject to these Terms and Conditions, and unless otherwise extended or agreed to by alfarok, the term of the License for each type of Service is as follows: (i) for Alpha and/or Beta Services, 30 days; (ii) for Trial Services, 90 days; and (iii) for Member Services, one year, subject to automatic renewal (each a “License Term”). The License Term shall commence on the date that you first accept these Terms and Conditions or that you first access the Services, whichever is earlier. Accessing the Services beyond the applicable License Term or any attempt to defeat any time-control disabling function in the Services is an unauthorized use and constitutes a material breach of this Agreement.</p>
    
    <h3>1.3. Compliance and Limitations.</h3>
    
    <p>The License is conditioned on Your continuous compliance with all terms, conditions, limitations, and restrictions set forth in these Terms and Conditions. If You violate any of these terms, conditions, limitations, and restrictions, the License will automatically and immediately terminate. The license parameters set forth below define the scope of rights that alfarok grants to You as part of the License. Your access or use of the Services in any manner not explicitly permitted in these Terms and Conditions or otherwise agreed to in writing by alfarok constitutes an infringement of alfarok’s intellectual property rights as well as a material breach of these Terms and Conditions. No license is granted under these Terms and Conditions as a result of any unlawful or unauthorized access to the Services.</p>
    
    <h3>1.4. License Parameters.</h3>
    
    <p>The License is subject to one or more of the License Parameters defined below (“License Parameters”) as applicable to the Services You have subscribed to.</p>
    
    <h3>1.4.1. Alpha and/or Beta Services.</h3>
    
    <p>If You have subscribed to Alpha and/or Beta Services, You may access the Services solely for the purpose of commercial evaluation and demonstration. Without limiting the foregoing, You may not use Alpha and/or Beta Services for competitive analysis or commercial, professional, or other for-profit purposes. Unless provided otherwise in writing by alfarok, Alpha and/or Beta Services may be accessed for a 30-day evaluation period. You acknowledge that Alpha and/or Beta Services are subject to modification by alfarok during the License Term. Such modification may include, but is not limited to, functionality changes, display changes, code revisions, server tests, and the like. Alpha and/or Beta Services may include diminished performance, functionality, or downtime of part or all of the Services at various times throughout the License Term, as alfarok works to improve the Services. You acknowledge that the nature of Alpha and/or Beta Services are for testing and development and hereby release alfarok from any claims whatsoever that may arise from Your use of Alpha and/or Beta Services. As a subscriber to Alpha and/or Beta Services, You may also be asked from time to time to provide feedback or comments about the functionality and performance of the Services. You agree that alfarok may use in any manner it chooses all feedback and comments that You provide, and You consent to alfarok contacting You in response to any feedback or comments that You provide.</p>
    
    <h3>1.4.2. Trial Services.</h3>
    
    <p>If You have subscribed to Trial Services, You and Your employees may access the Services solely for Your individual commercial or non-commercial needs. Unless provided otherwise in writing by alfarok, Trial Services may be accessed for a 90-day License Term. You shall not allow, and shall take reasonable precautions to prevent, the Services to be accessed by anyone other than You or Your employees, unless otherwise explicitly permitted in these Terms and Conditions or in writing by alfarok. At the end of the License Term, absent renewal by You, Your access to the Services will terminate.</p>
    
    <h3>1.4.3. Member Services.</h3>
    
    <p>If You have subscribed to Member Services, You and Your employees may access the Services solely for Your individual commercial or non-commercial needs. Unless provided otherwise in writing by alfarok, Member Services may be accessed for a one-year License Term, subject to automatic renewal unless terminated sooner by You or alfarok, in accordance with these Terms and Conditions. You shall not allow, and shall take reasonable precautions to prevent, the Services to be accessed by anyone other than You or Your employees, unless otherwise explicitly permitted in these Terms and Conditions or in writing by alfarok.</p>
    
    <h3>1.5. Prohibited Actions.</h3>
    
    <h3>1.5.1. No Reverse Engineering.</h3>
    
    <p>Except as expressly permitted by this License or as required by law, You may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of any aspect of the Services, specifically including the COINFEED® online software or any part thereof. You shall not utilize any equipment, device, software, or other means designed to circumvent or remove any usage restrictions or enable functionality disabled by alfarok in connection with the Services. You shall not bypass or delete any functionality or technical limitations of the Services that prevent or inhibit the unauthorized copying or use.</p>
    
    <h3>1.5.2. Transfers.</h3>
    
    <p>You shall not distribute, rent, loan, lease, sell, sublicense, or otherwise transfer all or any portion of the Services, or any rights granted in these Terms and Conditions, to any other person or entity without the prior written consent of alfarok.</p>
    
    <h3>1.5.3. Hosting or Third-Party Use.</h3>
    
    <p>You shall not facilitate any third-party’s unauthorized access to the Services or make the Services available to third parties via the Internet, on Your computer system or network, or otherwise.</p>
    
    <h3>1.5.4. Export.</h3>
    
    <p>You shall not export, directly or indirectly, any technical data acquired through use of the Services to any country for which the United States government or any agency thereof, at the time of export, requires an export license or other government approval, without first obtaining such license or approval. The obligations set forth in this Section shall survive any termination of this Agreement.</p>
    
    <h3>2. Intellectual Property Rights.</h3>
    
    <h3>2.1. Proprietary Rights of alfarok.</h3>
    
    <p>You acknowledge and agree that the Services, the COINFEED® online software, and all underlying and generated data are the exclusive, confidential, proprietary work product of alfarok and are protected by trade secret, copyright and other intellectual property laws. No reproduction or distribution of this Data beyond individuals authorized by the regulatory agency for which it was created is permitted without the express prior written consent of alfarok. The structure, organization, and code of the COINFEED® online software are valuable trade secrets of alfarok, and You agree to keep such trade secrets confidential. Except for the limited right to use the COINFEED® online software and access the Services as provided for herein, these Terms and Conditions do not grant You any rights in the COINFEED® online software or any aspect of the Services. You acknowledge that title, ownership, and all intellectual property rights in and to the COINFEED® online software and all aspects of the Services remain in alfarok.</p>
    
    <h3>2.2. Trademarks.</h3>
    
    <p>All trademarks, logos, and service marks (collectively, “Trademarks”) associated with the Services or the COINFEED® online software, including “alfarok llc,” “alfarok,” and “COINFEED,” are registered, unregistered, or otherwise protected trademarks owned by alfarok or licensed for use by alfarok. Nothing in these Terms and Conditions, nor any aspects of the Services, should be construed as granting, by implication or otherwise, any license or right to use any Trademark without alfarok’s prior written permission. All of the Trademarks and goodwill associated therewith shall remain the sole and exclusive property of alfarok.</p>
    
    <h3>2.3. Copyrights.</h3>
    
    <p>Except as otherwise provided in these Terms and Conditions, all Services, all portions of the COINFEED® online software, and any data or other output generated by the COINFEED® online software are the exclusive property of alfarok or its affiliates and is protected by U.S. and international copyright laws. Any software, functionality, or components subject to open source licensing is excluded from this provision.</p>
    
    <h3>2.4. Patents.</h3>
    
    <p>Certain aspects of the Services and the COINFEED® online software, including data generated by such software and information underlying the Services are protected by issued and pending patents in the United States and elsewhere. Except for the limited License granted herein to access the Services, no rights are granted in and to any technology embodied in any of alfarok’s patents or patent applications.</p>
    
    <h3>2.5. Preservation of Notices.</h3>
    
    <p>You shall preserve and shall not remove, alter, or obscure any intellectual property or other proprietary notices provided on, as part of, or in connection with alfarok llc, including but not limited to trademark notices, copyright attributions, and patent markings.</p>
    
    <h3>3. Access and Availability of the Services.</h3>
    
    <h3>3.1. Unlawful Use Prohibited.</h3>
    
    <p>You agree not to use the Services for any unlawful purpose. The Company reserves the right to terminate or restrict your access to the Services if your use of the Services may violate any laws, regulations, or rulings; infringe upon another person’s rights; or violate the terms of these Terms and Conditions.</p>
    
    <h3>3.2. Technical Requirements.</h3>
    
    <p>You acknowledge that all features and content of the Services may be subject to availability and suitability of an Internet connection or cellular network, valid email account, computer equipment or mobile or tablet device, suitable operating system, and sufficiently available bandwidth at the time of your attempted use or access. You are solely responsible for procuring the necessary equipment and data connection required for accessing and using the Services.</p>
    
    <h3>3.3. Upgrades.</h3>
    
    <p>The Services are subject to upgrades in performance and functionality from time to time within alfarok’s sole discretion. The Services will be updated by alfarok during the License Term to incorporate any such upgrades. It is Your responsibility to ensure that Your equipment complies with all requirements to access the Services. If any upgrades require enhanced software or hardware performance from a prior version of the Services, alfarok will endeavor to provide You with advance notice; however, it remains Your sole responsibility to access the Services through appropriate equipment.</p>
    
    <h3>4. Login Information.</h3>
    
    <p>Your login credentials for access to the Services are deemed confidential information and shall be treated as such. You are permitted to share Your login credentials with or otherwise provide access to and use of the Services to only employees of Your organization who have a reasonable need to access the Services within the scope of their employment duties to You. By using Your login credentials to access the Services, You affirm that You are the authorized person or entity to which the login credentials were provided. You shall not share or otherwise permit use of Your login credentials with third parties other than with the prior written consent of alfarok. Any unauthorized access to the Services is punishable by law.</p>
    
    <h3>5. Representations and Warranties.</h3>
    
    <p>By agreeing to these Terms and Conditions and accessing the Services, You hereby represent and warrant that You are personally authorized by alfarok, or are the duly authorized representative of a company or regulatory agency authorized by alfarok, to use the Services. alfarok endeavors to ensure the accuracy and performance of the Services; however, errors in the Services or the COINFEED® online software may occur. YOUR USE OF AND RELIANCE ON THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS AND WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY LAW, alfarok EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. alfarok makes no warranty: (i) that the Services will meet Your requirements; (ii) that the COINFEED® online software will be complete or error-free or not subject to Downtime (defined below); (iii) regarding the security, reliability, or timeliness of the Services; or (iv) that any errors in the Services will be corrected. Nothing in these Terms and Conditions are intended to exclude or limit any condition, warranty, right, or liability that may not be lawfully excluded or limited. Some states or other jurisdictions do not allow the exclusion of implied warranties, so the above exclusions may not apply to You. You may also have other rights that vary from state to state and jurisdiction to jurisdiction.</p>
    
    <h3>6. Downtime.</h3>
    
    <p>“Downtime” shall mean a malfunction in a core component of the Services or the COINFEED® online software that prevents Your access to the Services, which malfunction was caused solely by a failure of alfarok’s computer or server equipment controlling the same, exclusive of (i) any failures caused by or attributable in whole or in part to You or Your service provider or other vendor providing services to You, (ii) events beyond the reasonable control of alfarok, or (iii) cessation of use due to anticipated or scheduled maintenance of the Services. If You notify alfarok in writing of any Downtime, alfarok shall use commercially reasonable efforts to restore access to the Services as promptly and practicable under the circumstances. alfarok shall be responsible only for prospectively remedying such Downtime. You agree that alfarok’s liability for any losses or damages incurred by You or a third party during or as a result of such Downtime shall be limited to Your subscription fee for the applicable License Term.</p>
    
    <h3>7. Limitation of Liability.</h3>
    
    <p>In using this site and accessing the Services, You agree to assume all risks related to reliance on any information and Data contained herein. You agree that alfarok shall not be held liable for any damages, including but not limited to direct, indirect, special, or consequential damages arising out of, resulting from, or in any way related to access or use of the Services, including unavailability of Services during Downtime, whether or not based upon warranty, contract, tort, or otherwise; whether or not injury was sustained by persons or property or otherwise; and whether or not loss was sustained from, or arose out of, the use of the Services; and whether or not alfarok was informed of possible damage or loss in advance. The foregoing limitations on liability shall apply notwithstanding a failure of essential purpose of any limited remedy and to the fullest extent permitted by law. Under no circumstances shall alfarok be liable to You or any user on account of Your use or misuse of the Services.</p>
    
    <h3>8. Indemnification.</h3>
    
    <p>You agree to hold harmless and indemnify alfarok and its subsidiaries, affiliates, officers, agents, and employees from and against any claim, suit or action, including any liability or expense arising from: all claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees, of every kind and nature, arising from or in any way related to Your access or use of, or reliance on, the Services or Your actions pursuant to or within the scope of these Terms and Conditions; Your violation of these Terms and Conditions; Your negligent acts or omissions or willful misconduct; or any allegation that Your activities separate and apart from merely accessing the Services infringe upon the rights of any third party or otherwise violates state or federal law.</p>
    
    <h3>9. Termination; Events upon Termination.</h3>
    
    <h3>9.1. Termination for Cause.</h3>
    
    <p>If You breach any of Your obligations in these Terms and Conditions, then alfarok shall have the right to terminate the License and cancel all access to the Services upon 30 days written notice (“Notice of Termination”). A notice of termination shall become effective 30 days after it is delivered to You if: (i) the breach is not capable of being cured; (ii) the breach is capable of being cured but You have not have fully and completely cured the breach within such 30-day period; or (iii) a breach of the same type that is the subject of the Notice of Termination has occurred twice in a single 30-day period.</p>
    
    <h3>9.2. Bankruptcy.</h3>
    
    <p>alfarok may, in its sole discretion and upon written notice to You, terminate the License and cancel all access to the Services if: (i) You file a petition in bankruptcy or are adjudicated bankrupt; (ii) a petition in bankruptcy is filed against You and not dismissed within 60 days of filing; (iii) You become insolvent or make an assignment for the benefit of Your creditors or pursuant to any bankruptcy law or if a receiver is appointed for You; or (iv) an order is made or an effective resolution passed for Your winding-up, liquidation, or dissolution.</p>
    
    <h3>9.3. Effect of Termination.</h3>
    
    <p>Upon termination or expiration of Your License, You shall immediately cease all access to the Services and pay any outstanding amounts due to alfarok under the terms of Your subscription.</p>
    
    <h3>9.4. No Responsibility for Work in Progress.</h3>
    
    <p>You acknowledge that alfarok shall not be responsible for any unfinished work in progress or data generated by the Services, and that upon termination of Your License alfarok is under no obligation to preserve any work or data that resides on alfarok’s servers.</p>
    
    <h3>9.5. Reservation of Rights.</h3>
    
    <p>Termination or expiration of the License shall be without prejudice to any rights which alfarok may have.</p>
    
    <h3>10. Compliance with Laws.</h3>
    
    <p>You shall ensure that any activities undertaken by You pursuant to these Terms and Conditions and any access or use of the Data shall comply with all laws, rules, and regulations of the United States and other applicable jurisdictions in which You are located, operate, or do business, as such may be amended and in effect from time to time. You agree that You shall not use, or assist any other person or entity in using, the Data for any unlawful purpose. alfarok reserves the right to terminate Your account, de-activate Your login credentials, and seek all available legal and equitable remedies if alfarok determines, in its sole discretion, that Your use of the Data violates any laws, regulations, or rulings or violates these Terms and Conditions.</p>
    
    <h3>11. Miscellaneous.</h3>
    
    <h3>11.1. Severability.</h3>
    
    <p>If any provision of these Terms and Conditions is declared void or unenforceable by any court or other competent tribunal, then all remaining provisions of these Terms and Conditions shall remain in full force and effect unless otherwise agreed to in writing by alfarok.</p>
    
    <h3>11.2. Relation to Other Agreements.</h3>
    
    <p>These Terms and Conditions are in addition to, and not to the exclusion of, any other written agreements between You and alfarok.</p>
    
    <h3>11.3. Choice of Law and Forum.</h3>
    
    <p>These Terms and Conditions will be governed by and construed in accordance with the laws of the State of New York, without giving effect to the conflict of laws provisions of New York or Your actual state or country of residence. Any action or proceeding between You and alfarok relating to or arising out of these Terms and Conditions, Your access of the Services, or Your use of the COINFEED® online software shall be commenced and maintained exclusively in the state or federal courts in the State of New York, and You hereby consent to jurisdiction and venue of any state or federal court of general jurisdiction in the State of New York.</p>
    
    <h3>11.4. Negation of Agency and Similar Relationships.</h3>
    
    <p>Nothing contained herein shall be deemed to create an agency, joint venture, or partnership relationship between the parties hereto.</p>
    
    <h3>11.5. Entire Agreement; Modifications to be in Writing; No Waiver.</h3>
    
    <p>These Terms and Conditions constitute the entire agreement between You and alfarok and supersede any other previous or contemporaneous communications, agreements, representations, or advertising with respect to the Services and the COINFEED® online software. There are no representations, promises, warranties, covenants, or undertakings other than those contained in this Agreement. No modification or claimed waiver of any of the Terms and Conditions herein shall be valid unless in writing signed by authorized representatives of the party against whom such modification or waiver is sought to be enforced. The failure of either Party to enforce, or the delay by either Party in enforcing, any of its rights under these Terms and Conditions shall not be deemed a continuing waiver or a modification thereof, and either Party may, within the time provided by applicable law, commence appropriate legal proceeding to enforce any or all of such rights.</p>
    
    <h3>11.6. General Assurances.</h3>
    
    <p>The parties hereto agree to execute, acknowledge, and deliver all such further instruments, and to do all such other acts, as may be necessary or appropriate in order to carry out the intent and purpose of these Terms and Conditions.</p>
    
    <h3>11.7. Assignment.</h3>
    
    <p>This Agreement and any rights hereunder are non-transferable by You and any purported assignment by You shall be void. This Agreement may be assigned or transferred by alfarok to any subsequent purchaser of all or substantially all of alfarok’s assets or to an affiliate of alfarok, provided such affiliate is owned, at least in part, by alfarok or one or more principals of alfarok.</p>
    
    <h3>11.8. Headings.</h3>
    
    <p>Headings or titles to sections or subsections in this Agreement are for convenience of reference only and shall not affect the meaning or interpretation of this Agreement or any part hereof.</p>
    
    <br />
    <Button icon="pi pi-times" label="Decline Terms" @click="declineTerms()" id="declineButton"/>
    <Button icon="pi pi-check" label="Accept Terms" @click="acceptTerms()" id="acceptButton"/>
  </div>
</template>

<script>
import Button from "primevue/button";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "CoinFeedTerms",
  components: {
    Button,
  },
  props: ['user'],
  data() {
    return {
    }
  },
  methods: {
    declineTerms() {
      this.$emit('signout');
    },
    async acceptTerms() {
      this.$emit('updatingLoadingStatus', true);
      const token = this.user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}AcceptUserAgreement`;
      const headers = { headers: { Authorization: token.jwtToken } };

      await axios
        .get(url, headers)
        .then((response) => {
          if(response.data.verified === true) {
            // Emit update to Home
            this.$emit('updateUserAgreementStatus', true);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$emit('updatingLoadingStatus', false);
    },
  },
};
</script>

<style scoped>
/*@import url('https://fonts.googleapis.com/css?family=Orbitron+Bold');*/

#terms{
  margin: auto;
  width: 95%;
  padding-bottom: 25px;
}
#declineButton {
  position: absolute;
  right: 52%;
}
#acceptButton {
  position: absolute;
  left: 52%;
}
h1 {
  font-family: sans-serif;
}
h3 {
  font-family: sans-serif;
}
p {
  margin: 10px;
  text-align: justify;
  text-justify: inter-word;
}

</style>