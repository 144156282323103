<template>
  <div style="margin: auto; min-height: 200px">
    <canvas id="depthChart"></canvas>
    <ProgressBar v-show="loading" mode="indeterminate" />
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Stream from "./../utilities/tvjs-stream.js";
import ProgressBar from "primevue/progressbar";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;
var Chart = require("chart.js");

export default {
  name: "OrderBook",
  components: {
    ProgressBar,
  },
  props: ["coin"],
  watch: { 
    coin: function(/*newVal, oldVal*/) {
      if (this.stream) this.stream.off();
      this.init();
    },
  },
  data() {
    return {
      loading: false,
      initialOrderId: undefined,
      orderBook: undefined,
      bids: {},
      asks: {},
      bubbleRadius: 1.5,
      chart: undefined,
      basicData: {
        datasets: [
          {
            type: "bubble",
            label: "bids",
            data: [],
            borderColor: "rgba(0, 183, 70, 0)",
            backgroundColor: "rgba(0, 183, 70, 1.0)",
          },
          {
            type: "bubble",
            label: "asks",
            data: [],
            borderColor: "rgba(239, 64, 60, 0)",
            backgroundColor: "rgba(239, 64, 60, 1.0)",
          },
          {
            type: "line",
            label: "bids cumulative",
            data: [],
            borderColor: "rgba(0, 183, 70, 0.25)",
            backgroundColor: "rgba(0, 183, 70, 0.5)",
          },
          {
            type: "line",
            label: "asks cumulative",
            data: [],
            borderColor: "rgba(239, 64, 60, 0.25)",
            backgroundColor: "rgba(239, 64, 60, 0.5)",
          },
        ],
      },
      options: {
        animation: {
          duration: 0,
        },
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: true,
          text: "Depth Chart",
          fontColor: "#e6e6e6",
        },
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          xAxes: [
            {
              type: "linear",
              scaleLabel: {
                display: true,
                labelString: "Price",
                fontColor: "#e6e6e6",
              },
              gridLines: {
                color: "rgba(56, 56, 56, 0.5)",
              },
            },
          ],
          yAxes: [
            {
              type: "linear",
              scaleLabel: {
                display: true,
                labelString: "Quantity",
                fontColor: "#e6e6e6",
              },
              gridLines: {
                color: "rgba(56, 56, 56, 0.5)",
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    init() {
      // Process (https://github.com/binance/binance-spot-api-docs/blob/master/web-socket-streams.md#how-to-manage-a-local-order-book-correctly)
      // 1) Open a stream to wss://stream.binance.com:9443/ws/bnbbtc@depth
      // 2) Buffer the events you receive from the stream
      // 3) Get a depth snapshot from https://api.binance.com/api/v3/depth?symbol=BNBBTC&limit=1000
      // 4) Drop any event where u is <= lastUpdateId in the snapshot
      // 5) The first processed event should have U <= lastUpdateId+1 AND u >= lastUpdateId+1
      // While listening to the stream, each new event's U should be equal to the previous event's u+1
      // The data in each event is the absolute quantity for a price level
      // 6) If the quantity is 0, remove the price level
      // Receiving an event that removes a price level that is not in your local order book can happen and is normal

      // 1) Open a stream to wss://stream.binance.com:9443/ws/bnbbtc@depth.
      this.stream = new Stream(
        `wss://stream.binance.com:9443/ws/${this.coin.toLowerCase()}usdt@depth`
      );
      // 2) Buffer the events you receive from the stream
      this.stream.ontrades = this.on_trades;

      // depth?symbol=SYMBOLUSDT&limit=1000
      this.updateSnapshot();
    },
    async updateSnapshot() {
      // 3) Get a depth snapshot from depth?symbol=SYMBOLUSDT&limit=1000
      //this.loading = true;
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}getOrderBookDepth`;
      const headers = { headers: { Authorization: token.jwtToken } };
      const body = JSON.stringify({
        symbol: this.coin,
      });
      await axios
        .post(url, body, headers)
        .then((response) => {
          this.orderBook = response.data.data;
          this.updateGraph();

          // New chart
          if (this.chart === undefined) {
            var ctx = document.getElementById("depthChart").getContext("2d");
            this.chart = new Chart(ctx, {
              type: "bubble",
              data: this.basicData,
              options: this.options,
            });
          }
          // Update chart
          else {
            this.chart.chart.update();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          //this.loading = false;
        });
    },
    updateGraph() {
      this.initialOrderId = this.orderBook.lastUpdateId;

      this.bids = {};
      this.basicData.datasets[0].data = [];
      for (let i = 0; i < this.orderBook.bids.length; i++) {
        const bid = this.orderBook.bids[i];
        this.bids[bid[0]] = parseFloat(bid[1]);
        this.basicData.datasets[0].data.push({
          x: bid[0],
          y: bid[1],
          r: this.bubbleRadius,
        });
      }

      this.asks = {};
      this.basicData.datasets[1].data = [];
      for (let i = 0; i < this.orderBook.asks.length; i++) {
        const ask = this.orderBook.asks[i];
        this.asks[ask[0]] = parseFloat(ask[1]);
        this.basicData.datasets[1].data.push({
          x: ask[0],
          y: ask[1],
          r: this.bubbleRadius,
        });
      }

      let cumulativeSum = (
        (sum) => (value) =>
          (sum += value)
      )(0);

      let orderedBids = [];
      /* eslint-disable no-unused-vars */
      for (const [key, value] of Object.entries(this.bids)) {
        orderedBids.push(value);
      }
      //orderedBids.sort(function (a, b) { return b - a; });
      orderedBids = orderedBids.map(cumulativeSum);

      // reset
      cumulativeSum = (
        (sum) => (value) =>
          (sum += value)
      )(0);

      //this.asks = asks.sort(function (a, b) { return b - a; });
      let orderedAsks = [];
      for (const [key, value] of Object.entries(this.asks)) {
        orderedAsks.push(value);
      }
      //orderedAsks.sort(function (a, b) { return b - a; });
      orderedAsks = orderedAsks.map(cumulativeSum);

      this.basicData.datasets[2].data = [];
      let count = 0;
      for (const [key, value] of Object.entries(this.bids)) {
        const item = {
          x: parseFloat(key),
          y: parseFloat(orderedBids[count]),
          r: this.bubbleRadius,
        };
        this.basicData.datasets[2].data.push(item);
        count += 1;
      }

      this.basicData.datasets[3].data = [];
      count = 0;
      for (const [key, value] of Object.entries(this.asks)) {
        const item = {
          x: parseFloat(key),
          y: parseFloat(orderedAsks[count]),
          r: this.bubbleRadius,
        };
        this.basicData.datasets[3].data.push(item);
        count += 1;
      }
      /* eslint-enable no-unused-vars */
    },
    on_trades(data) {
      /*
			e: "depthUpdate", // Event type
  		E: 123456789,     // Event time
			s: "BNBBTC",      // Symbol
			U: 157,           // First update ID in event
			u: 160,           // Final update ID in event
			b: [              // Bids to be updated
				[
					"0.0024",       // Price level to be updated
					"10"            // Quantity
				]
			],
			a: [              // Asks to be updated
				[
					"0.0026",       // Price level to be updated
					"100"           // Quantity
				]
			]
			*/
      if (this.initialOrderId !== undefined) {
        // 4) Drop any event where u is <= lastUpdateId in the snapshot
        if (data.u <= this.initialOrderId) {
          return;
        }
        /*
        // 5) The first processed event should have U <= lastUpdateId+1 AND u >= lastUpdateId+1
        else if (
          data.U <= this.initialOrderId + 1 &&
          data.u >= this.initialOrderId + 1
        ) {
          this.orderBook.lastUpdateId = data.u;

          for (let i = 0; i < data.b.length; i++) {
            const bid = data.b[i];
            if (bid[1] === "0.00000000") {
              //console.log('deleting bid');
              delete this.orderBook.bids[bid[0]];
            } else {
              //console.log('adding bid');
              this.orderBook.bids[bid[0]] = bid[1];
            }
          }

          for (let i = 0; i < data.a.length; i++) {
            const ask = data.a[i];
            if (ask[1] === "0.00000000") {
              //console.log('deleting ask');
              delete this.orderBook.asks[ask[0]];
            } else {
              //console.log('adding ask');
              this.orderBook.asks[ask[0]] = ask[1];
            }
          }

          this.updateGraph();
          // Update Chart
          this.chart.chart.update();
        }
        */ 
        else {
          // console.log("NEW SNAPSHOT");
          this.updateSnapshot();
        }
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.stream) this.stream.off();
  },
};
</script>
