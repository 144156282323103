var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
    _vm.authState === 'signin' ||
    _vm.authState === 'signedout' ||
    _vm.authState === 'signup' ||
    _vm.authState === 'confirmSignIn' ||
    _vm.authState === 'confirmSignUp' ||
    _vm.authState === 'forgotpassword' ||
    _vm.verifiedBetaUser === undefined ||
    _vm.acceptedUserTerms === undefined ||
    _vm.verifiedBetaUser === false ||
    _vm.acceptedUserTerms === false ||
    _vm.coins === undefined ||
    _vm.userSettings === undefined)?_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col"},[_c('div',[_c('CoinFeed3D'),(_vm.authState === 'signin')?_c('div',[_c('a',[_vm._v(" Real-time tracking, analysis and notifications for crypto assets. ")])]):_vm._e(),(
          _vm.authState === 'signedin' && _vm.verifiedBetaUser === undefined ||
          _vm.authState === 'signedin' && _vm.verifiedBetaUser === true && _vm.acceptedUserTerms === undefined ||
          _vm.authState === 'signedin' && _vm.coins === undefined ||
          _vm.authState === 'signedin' && _vm.userSettings === undefined)?_c('div',{attrs:{"id":"loadingDiv"}},[_c('a',{attrs:{"id":"loadingStatus"}},[_vm._v(_vm._s(_vm.loadingStatus))]),_c('br'),_c('br'),_c('ProgressBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"mode":"indeterminate"}})],1):_vm._e(),_c('br')],1),_c('amplify-authenticator',{attrs:{"id":"authenticator","username-alias":"email"}},[_c('amplify-sign-in',{attrs:{"slot":"sign-in","username-alias":"email"},slot:"sign-in"}),_c('amplify-sign-up',{attrs:{"slot":"sign-up","username-alias":"email"},domProps:{"formFields":_vm.formFields},slot:"sign-up"})],1)],1)]):_vm._e(),(_vm.authState === 'signedin' && _vm.verifiedBetaUser === false)?_c('div',[_vm._m(0),_c('br'),_vm._v(" "),_c('br'),_c('a',{attrs:{"id":"signout"},on:{"click":this.signout}},[_vm._v("signout")])]):_vm._e(),(_vm.authState === 'signedin' && _vm.verifiedBetaUser === true && _vm.acceptedUserTerms === false)?_c('div',{attrs:{"id":"terms"}},[_c('CoinfeedTerms',{attrs:{"user":_vm.user},on:{"updatingLoadingStatus":_vm.updatingLoadingStatus,"updateUserAgreementStatus":_vm.updateUserAgreementStatus,"signout":_vm.signout}}),_c('br'),_c('br'),_c('ProgressBar',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"mode":"indeterminate"}})],1):_vm._e(),(_vm.authState === 'signedin' && _vm.verifiedBetaUser === true && _vm.acceptedUserTerms === true && _vm.coins && _vm.userSettings)?_c('div',[_c('NavBar',{attrs:{"coins":_vm.coins},on:{"signout":_vm.signout}}),_c('router-view',{attrs:{"user":_vm.user,"coins":_vm.coins,"marquee":_vm.marquee,"userSettings":_vm.userSettings},on:{"updateUserSettings":_vm.updateUserSettings}}),_vm._m(1)],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{attrs:{"id":"nonBetaUser"}},[_vm._v(" Thank you for signing up for coinfeed. We are currently operating under an invite-only alpha program. If you are interested in participating please reach out to: "),_c('a',{attrs:{"href":"mailto: admin@coinfeed.org","target":"_blank"}},[_vm._v(" admin@coinfeed.org ")]),_vm._v(". ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{attrs:{"id":"footer"}},[_c('div',[_c('b',[_vm._v(" COINFEED IS NOT FINANCIAL ADVICE AND IS INTENDED FOR INFORMATIONAL PURPOSES ONLY. ")]),_c('br'),_c('br'),_c('a',{staticClass:"socialIcons",attrs:{"href":"https://twitter.com/coinfeed_org","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"pi pi-twitter",staticStyle:{"font-size":"2rem"}})]),_vm._v("  "),_c('a',{staticClass:"socialIcons",attrs:{"href":"https://discord.gg/NKkgSgrpfA","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"pi pi-discord",staticStyle:{"font-size":"2rem"}})]),_vm._v("  "),_c('a',{staticClass:"socialIcons",attrs:{"href":"https://www.instagram.com/coinfeed_org","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("../assets/instagram_icon_small.png")}})]),_c('br'),_c('a',{attrs:{"href":"mailto: admin@coinfeed.org","target":"_blank"}},[_vm._v("© 2021 alfarok llc")])])])}]

export { render, staticRenderFns }