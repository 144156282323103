<template>
  <div class="frontCandleCharts" style="pointer-events: none;">
    <trading-vue
      ref="chart"
      id="chart"
      :data="chart"
      :title-txt="this.coin"
      :color-title="colors.colorTitle"
      :color-back="colors.colorBack"
      :color-cross="colors.colorCross"
      :color-grid="colors.colorGrid"
      :color-text="colors.colorText"
      :colorTextHL="colors.highlighted"
      :colorScale="colors.colorScale"
      :color-panel="colors.colorPanel"
      :font="font"
      :height="height"
      :width="width"
    ></trading-vue>
    <ProgressBar v-show="loading" mode="indeterminate"/>
  </div>
</template>
<script>
import { TradingVue, DataCube } from "trading-vue-js";
import { Auth } from "aws-amplify";
import ProgressBar from 'primevue/progressbar';

import Const from './../utilities/tvjs-constants.js';
import Stream from './../utilities/tvjs-stream.js';
import Utils from './../utilities/tvjs-utils.js';

const axios = require("axios");

const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "Candlestick",
  components: { 
    TradingVue,
    ProgressBar,
  },
  props: ["coin", "selectedInterval", "toggleChartType"],
  data() {
    return {
      chart: { },
      settings: {
        color: '#93cbf9',
        colorCandleUp: "#00B746",
        colorCandleDw: "#EF403C",
        colorWickUp: "#00B746",
        colorWickDw: "#EF403C",
        colorWickSm: "#e6e6e6",
        colorVolUp: "rgb(0, 183, 70, 0.5)",
        colorVolDw: "rgb(239, 64, 60, 0.5)",
        priceLine: "#e6e6e6",
      },
      colors: {
        colorTitle: "#e6e6e6",
        colorBack: "rgba(0, 0, 0, 0.0)",
        colorCross: "rgba(56, 56, 56, 0.5)",
        colorGrid: "rgba(56, 56, 56, 0.5)",
        colorText: "#e6e6e6",
        highlighted: "#e6e6e6",
        colorScale: "rgba(255, 0, 0, 0.0)",
        colorPanel: "rgba(56, 56, 56, 0.5)",
      },
      font: "8px sans-serif",
      width: 400,
      height: 150,
      loading: true,
      chartType: 'Candles',
    };
  },
  methods: {
    async init() {
      // Load the last data chunk & init DataCube:
      let now = Utils.now()
      this.load_chunk([now - Const.HOUR4, now]).then(data => {
        this.chart = new DataCube({
          ohlcv: data['chart.data'],
          chart: {
            type: this.chartType,
            data: data['chart.data'],
            settings: this.settings,
          },
          tools: [{
            type: "LineTool:Segment",
            settings: {
              color: '#e6e6e6'
            },
          },
          {
            type: "LineTool:Extended",
            settings: {
              color: '#e6e6e6'
            },
          },
          {
            type: "LineTool:Ray",
            settings: {
              color: '#e6e6e6'
            },
          }
        ],
        datasets: [{
          type: 'Trades',
          id: 'binance-btcusdt',
          data: []
        }]
        });
        // Register onrange callback & And a stream of trades
        this.chart.onrange(this.load_chunk)
        this.$refs.chart.resetChart()
        this.stream = new Stream(`wss://stream.binance.com:9443/ws/${this.coin.toLowerCase()}usdt@aggTrade`)
        this.stream.ontrades = this.on_trades
        this.loading = false;
      });
    },
    async load_chunk() {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url =`${VUE_APP_AUTHENTICATED_API_URL}getKLines`;
      const headers = { headers: { Authorization: token.jwtToken } };
      const body = JSON.stringify({
        symbol: this.coin.toUpperCase(),
        interval: this.selectedInterval.interval,
      });
      let result = undefined;
      await axios
        .post(url, body, headers)
        .then((response) => {
          result = response.data.data;
          for(let i = 0; i < response.data.data.length; i++) {
            const utcDate =  new Date(response.data.data[i][0])
            const offset = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() - offset);
            const time = utcDate.getTime();
            response.data.data[i][0] = time;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      let r = result;
      return this.format(this.parse_binance(r))
    },
    // Parse a specific exchange format
    parse_binance(data) {
      if (!Array.isArray(data)) return []
      return data.map(x => {
        for (var i = 0; i < x.length; i++) {
          x[i] = parseFloat(x[i])
        }
        return x.slice(0,6)
      })
    },
    format(data) {
      // Each query sets data to a corresponding overlay
      return { 'chart.data': data }
    },
    on_trades(trade) {
      const utcDate =  new Date(trade.T);
      const offset = utcDate.getTimezoneOffset();
      utcDate.setMinutes(utcDate.getMinutes() - offset);
      const time = utcDate.getTime();
      this.chart.update({
        // Local time
        t: time,
        // Trade price
        price: parseFloat(trade.p),
        // Trade amount
        volume: parseFloat(trade.q),
        'datasets.binance-btcusdt': [ // Update dataset
          trade.T,
          trade.m ? 0 : 1,          // Sell or Buy
          parseFloat(trade.q),
          parseFloat(trade.p)
        ],
      })
    },
    onResize(/*event*/) {
      if(window.innerWidth / 3.25 > 400) {
        this.width = window.innerWidth / 3.25;
      }
      else {
        this.width = window.innerWidth * 0.95;
      }
    },
  },
  watch: { 
    coin: function(/*newVal, oldVal*/) {
      if (this.stream) this.stream.off();
      this.chart = new DataCube({
        chart: { },
      }),
      this.$refs.chart.resetChart();
      this.loading = true;
      this.init();
    },
    selectedInterval: function() {
      if (this.stream) this.stream.off();
      this.chart = new DataCube({
        chart: { },
      }),
      this.$refs.chart.resetChart();
      this.loading = true;
      this.init()
    },
    toggleChartType: function() {
      // Disconnect from previous ws
      if (this.stream) this.stream.off();
      this.chart = new DataCube({
        chart: { },
      }),
      this.$refs.chart.resetChart();
      this.loading = true;
      if(this.chartType === 'Candles') {
        this.chartType = 'Spline'
      } else {
        this.chartType = 'Candles'
      }
      this.init();
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize();
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    if (this.stream) this.stream.off()
  }
};
</script>

<style scoped>
#chart {
  display: table;
  margin: 0 auto;
}
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #e6e6e6 !important;
  }
}
</style>