<template>
  <div>
    Debugger
  </div>
</template>

<script>

export default {
  name: "Debugger",
  components: {
  },
  props: ['coins'],
  data() {
    return {};
  },
  methods: {
    async init() {
      console.log('Debugging');
    },
  },
  mounted() {
    this.init();
  },
};
</script>
