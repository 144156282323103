<template>
  <div>
    <!-- CHART -->
    <trading-vue
      ref="chartLarge"
      id="chartLarge"
      :data="chart"
      :overlays="overlays"
      :toolbar="true"
      :title-txt="this.coin"
      :color-title="colors.colorTitle"
      :color-back="colors.colorBack"
      :color-cross="colors.colorCross"
      :color-grid="colors.colorGrid"
      :color-text="colors.colorText"
      :colorTextHL="colors.highlighted"
      :colorScale="colors.colorScale"
      :color-panel="colors.colorPanel"
      :font="font"
      :width="width"
    ></trading-vue>

    <!-- OPTIONS MENU -->
    <div id="chartMenu">
      <span class="chartMenuItem">
        <label>Time Interval: </label>
        <Dropdown
          v-model="selectedInterval"
          :options="intervalOptions"
          optionLabel="interval"
        /> </span
      >&nbsp;
      <span class="chartMenuItem">
        <label>Indicator Data Points: </label>
        <Dropdown
          v-model="indicatorLength"
          :options="indicatorLengths"
        /> </span
      >&nbsp;
      <span class="chartMenuItem">
        <label>On-Chart Indicator: </label>
        <Dropdown
          v-model="selectedOnIndicator"
          :options="indicatorOnOptions"
          optionLabel="name"
          dataKey="name"
        /> </span
      >&nbsp;
      <span class="chartMenuItem">
        <label>Off-Chart Indicator: </label>
        <Dropdown
          v-model="selectedOffIndicator"
          :options="indicatorOffOptions"
          optionLabel="name"
          dataKey="name"
        /> </span
      >
      <br />
      <span class="chartMenuItem">
        <label>Indicator Visible: </label>
        <InputSwitch v-model="toggleIndicators" /> </span
      >&nbsp;
      <span>
        <label>Line/Candles: </label>
        <InputSwitch v-model="toggleChartType" />
      </span>
    </div>
    <ProgressBar v-show="loading" mode="indeterminate" />
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
import { TradingVue, DataCube } from "trading-vue-js";
import Overlays from "tvjs-overlays";
import ProgressBar from "primevue/progressbar";
import Dropdown from "primevue/dropdown";
import InputSwitch from "primevue/inputswitch";

import Const from "./../utilities/tvjs-constants.js";
import Stream from "./../utilities/tvjs-stream.js";
import Utils from "./../utilities/tvjs-utils.js";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "CandlestickLarge",
  components: {
    TradingVue,
    ProgressBar,
    Dropdown,
    InputSwitch,
  },
  props: ["coin"],
  data() {
    return {
      chart: {},
      overlays: [
        // On Chart Options
        Overlays["ALMA"],
        Overlays["BB"],
        Overlays["EMA"],
        Overlays["HMA"],
        Overlays["KC"],
        Overlays["Ribbon"],
        Overlays["SMA"],
        Overlays["SWMA"],
        Overlays["VWMA"],

        // Off Chart
        Overlays["ATR"],
        Overlays["ATRp"],
        Overlays["BBW"],
        Overlays["CCI"],
        Overlays["CMO"],
        Overlays["COG"],
        Overlays["DMI"],
        Overlays["KCW"],
        Overlays["MACD"],
        Overlays["MFI"],
        Overlays["MOM"],
        Overlays["ROC"],
        Overlays["RSI"],
        Overlays["Stoch"],
        Overlays["TSI"],
        Overlays["WilliamsR"],
      ],
      indicatorOnOptions: [
        {
          name: "ALMA",
          fullName: "Arnaud Legoux Moving Average",
          settings: {
            sigma: 5,
            offset: 0.9,
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "BB",
          fullName: "Bollinger Bands",
          settings: {
            stddev: 2,
            color: "#e6e6e6",
            length: this.indicatorLength,
            showMid: true,
            backColor: "rgba(56, 56, 56, 0.5)",
          },
        },
        {
          name: "EMA",
          fullName: "Exponential Moving Average",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "HMA",
          fullName: "Hull Moving Average",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "KC",
          fullName: "Keltner Channels",
          settings: {
            mult: 1,
            use_tr: true,
            color: "#e6e6e6",
            length: this.indicatorLength,
            showMid: true,
            backColor: "rgba(56, 56, 56, 0.5)",
          },
        },
        {
          name: "Ribbon",
          fullName: "Moving Average Ribbon",
          settings: {
            start: 10,
            number: 5,
            step: 10,
            colors: [
              "rgba(255, 255, 255, 0.2)",
              "rgba(255, 255, 255, 0.4)",
              "rgba(255, 255, 255, 0.6)",
              "rgba(255, 255, 255, 0.8)",
              "rgba(255, 255, 255, 1.0)",
            ],
          },
        },
        {
          name: "SMA",
          fullName: "Simple Moving Average",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "SWMA",
          fullName: "Symmetrically Weighted Moving Average",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "VWMA",
          fullName: "Volume Weighted Moving Average",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
      ],
      indicatorOffOptions: [
        {
          name: "ATR",
          fullName: "Average True Range",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "ATRp",
          fullName: "Average True Range %",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "BBW",
          fullName: "Bollinger Bands Width",
          settings: {
            stddev: 2,
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "DMI",
          fullName: "Directional Movement Index",
          settings: {
            smooth: 15,
            colors: ["#00ffff", "#ff00ff", "#ffff00"],
            length: this.indicatorLength,
          },
        },
        {
          name: "CCI",
          fullName: "Commodity Channel Index	",
          settings: {
            color: "#e6e6e6",
            upper: 100,
            lower: -100,
            backColor: "rgba(56, 56, 56, 0.25)",
            bandColor: "#666",
            length: this.indicatorLength,
          },
        },
        {
          name: "CMO",
          fullName: "Chande Momentum Oscillator",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "COG",
          fullName: "Center of Gravity",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "KCW",
          fullName: "Keltner Channels Width",
          settings: {
            mult: 1,
            use_tr: true,
            length: this.indicatorLength,
            color: "#e6e6e6",
          },
        },
        {
          name: "MACD",
          fullName: "Moving Average Convergence/Divergence",
          settings: {
            fast: 12,
            slow: 26,
            smooth: 9,
            histWidth: 4,
            macdWidth: 1,
            signalWidth: 1,
            defColor: "#00B746",
            macdColor: "#ff00ff",
            signalColor: "#ffff00",
            histColors: [
              "#00B746",
              "rgb(0, 183, 70, 0.5)",
              "#EF403C",
              "rgb(239, 64, 60, 0.5)",
            ],
          },
        },
        {
          name: "MFI",
          settings: {
            color: "#e6e6e6",
            backColor: "rgba(56, 56, 56, 0.25)",
            length: this.indicatorLength,
          },
        },
        {
          name: "MOM",
          fullName: "Momentum",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "ROC",
          fullName: "Rate of Change",
          settings: {
            color: "#e6e6e6",
            length: this.indicatorLength,
          },
        },
        {
          name: "RSI",
          fullName: "Relative Strength Index",
          settings: {
            upper: 70,
            lower: 30,
            length: this.indicatorLength,
            color: "#e6e6e6",
            backColor: "rgba(56, 56, 56, 0.25)",
            bandColor: "#666",
          },
        },
        {
          name: "Stoch",
          fullName: "Stochastic",
          settings: {
            param_k: 14,
            param_d: 3,
            smooth: 3,
            upper: 70,
            lower: 30,
            color: "#e6e6e6",
            backColor: "rgba(56, 56, 56, 0.25)",
            bandColor: "#666",
            kColor: "#ff00ff",
            dColor: "#ffff00",
          },
        },
        {
          name: "TSI",
          fullName: "True Strength Index",
          settings: {
            long: 25,
            short: 13,
            signal: 13,
            colors: ["#ff00ff", "#ffff00"],
          },
        },
        {
          name: "WilliamsR",
          fullName: "Williams %R",
          settings: {
            upper: -30,
            lower: -70,
            color: "#e6e6e6",
            backColor: "rgba(56, 56, 56, 0.25)",
            bandColor: "#666",
            length: this.indicatorLength,
          },
        },
      ],
      indicatorLengths: [10, 20, 30, 40, 50, 60, 70, 80, 100, 150, 200, 300],
      selectedOnIndicator: {
        name: "SMA",
        fullName: "Simple Moving Average",
        settings: {
          color: "#e6e6e6",
          length: this.indicatorLength,
        },
      },
      selectedOffIndicator: {
        name: "RSI",
        fullName: "Relative Strength Index",
        settings: {
          upper: 70,
          lower: 30,
          length: 60,
          color: "#e6e6e6",
          backColor: "rgba(56, 56, 56, 0.5)",
          bandColor: "#666",
        },
      },
      indicatorLength: 60,
      settings: {
        color: "#93cbf9",
        colorCandleUp: "#00B746",
        colorCandleDw: "#EF403C",
        colorWickUp: "#00B746",
        colorWickDw: "#EF403C",
        colorWickSm: "#e6e6e6",
        colorVolUp: "rgb(0, 183, 70, 0.5)",
        colorVolDw: "rgb(239, 64, 60, 0.5)",
        priceLine: "#e6e6e6",
      },
      colors: {
        colorTitle: "#e6e6e6",
        colorBack: "rgba(0, 0, 0, 0.0)",
        colorCross: "rgba(56, 56, 56, 0.5)",
        colorGrid: "rgba(56, 56, 56, 0.5)",
        colorText: "#e6e6e6",
        highlighted: "#e6e6e6",
        colorScale: "rgba(255, 0, 0, 0.0)",
        colorPanel: "rgba(56, 56, 56, 0.5)",
      },
      font: "8px sans-serif",
      btcSeries: [],
      ethSeries: [],
      adaSeries: [],
      exchange: "coinbasepro",
      timeframe: "1m",
      loading: true,
      width: 400,
      intervalOptions: [
        { interval: "1m" },
        { interval: "3m" },
        { interval: "5m" },
        { interval: "15m" },
        { interval: "30m" },
        { interval: "1h" },
        { interval: "2h" },
        { interval: "4h" },
        { interval: "6h" },
        { interval: "8h" },
        { interval: "12h" },
        { interval: "1d" },
        { interval: "3d" },
        { interval: "1w" },
        { interval: "1M" },
      ],
      selectedInterval: { interval: "1m" },
      toggleChartType: true,
      toggleIndicators: true,
      chartType: "Candles",
    };
  },
  methods: {
    async init() {
      // Load the last data chunk & init DataCube:
      let now = Utils.now();

      // Check overlay  visibility
      const onChart = [];
      const offChart = [];
      if (this.toggleIndicators) {
        onChart.push({
          type: this.selectedOnIndicator.name,
          name: `${this.selectedOnIndicator.fullName}, ${this.indicatorLength} Data Points:`,
          data: [],
          settings: this.selectedOnIndicator.settings,
        });

        offChart.push({
          type: this.selectedOffIndicator.name,
          name: `${this.selectedOffIndicator.fullName}, ${this.indicatorLength} Data Points:`,
          data: [],
          settings: this.selectedOffIndicator.settings,
        });
      }

      this.load_chunk([now - Const.HOUR4, now]).then((data) => {
        this.chart = new DataCube({
          ohlcv: data["chart.data"],
          chart: {
            type: this.chartType,
            data: data["chart.data"],
            settings: this.settings,
          },
          onchart: onChart,
          offchart: offChart,
          tools: [
            {
              type: "LineTool:Segment",
              settings: {
                color: "#e6e6e6",
              },
            },
            {
              type: "LineTool:Extended",
              settings: {
                color: "#e6e6e6",
              },
            },
            {
              type: "LineTool:Ray",
              settings: {
                color: "#e6e6e6",
              },
            },
          ],
          datasets: [
            {
              type: "Trades",
              id: "binance-btcusdt",
              data: [],
            },
          ],
        });
        // Register onrange callback & And a stream of trades
        this.chart.onrange(this.load_chunk);
        this.$refs.chartLarge.resetChart();
        this.stream = new Stream(
          `wss://stream.binance.com:9443/ws/${this.coin.toLowerCase()}usdt@aggTrade`
        );
        this.stream.ontrades = this.on_trades;
        this.loading = false;
      });
    },
    onResize(/*event*/) {
      if (window.innerWidth > 400) {
        // Fill chart to 95% of inner window width
        this.width = window.innerWidth * 0.95;
      }
    },
    async load_chunk() {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}getKLines`;
      const headers = { headers: { Authorization: token.jwtToken } };
      const body = JSON.stringify({
        symbol: this.coin.toUpperCase(),
        interval: this.selectedInterval.interval,
      });
      let result = undefined;
      await axios
        .post(url, body, headers)
        .then((response) => {
          result = response.data.data;
          for (let i = 0; i < response.data.data.length; i++) {
            const utcDate = new Date(response.data.data[i][0]);
            const offset = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() - offset);
            const time = utcDate.getTime();
            response.data.data[i][0] = time;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      let r = result;
      return this.format(this.parse_binance(r));
    },
    // Parse a specific exchange format
    parse_binance(data) {
      if (!Array.isArray(data)) return [];
      return data.map((x) => {
        for (var i = 0; i < x.length; i++) {
          x[i] = parseFloat(x[i]);
        }
        return x.slice(0, 6);
      });
    },
    format(data) {
      // Each query sets data to a corresponding overlay
      return { "chart.data": data };
    },
    on_trades(trade) {
      const utcDate = new Date(trade.T);
      const offset = utcDate.getTimezoneOffset();
      utcDate.setMinutes(utcDate.getMinutes() - offset);
      const time = utcDate.getTime();
      this.chart.update({
        // Local time
        t: time,
        // Trade price
        price: parseFloat(trade.p),
        // Trade amount
        volume: parseFloat(trade.q),
        "datasets.binance-btcusdt": [
          // Update dataset
          trade.T,
          trade.m ? 0 : 1, // Sell or Buy
          parseFloat(trade.q),
          parseFloat(trade.p),
        ],
      });
    },
    reDraw() {
      // Disconnect from previous ws
      if (this.stream) this.stream.off();
      (this.chart = new DataCube({
        chart: {},
      })),
        this.$refs.chartLarge.resetChart();
      this.loading = true;
      this.init();
    },
  },
  watch: {
    coin() {
      this.reDraw();
    },
    selectedInterval() {
      this.reDraw();
    },
    selectedOnIndicator() {
      this.reDraw();
    },
    selectedOffIndicator() {
      this.reDraw();
    },
    indicatorLength() {
      this.reDraw();
    },
    toggleIndicators() {
      this.reDraw();
    },
    toggleChartType() {
      // Disconnect from previous ws
      if (this.stream) this.stream.off();
      (this.chart = new DataCube({
        chart: {},
      })),
        this.$refs.chartLarge.resetChart();
      this.loading = true;
      if (this.chartType === "Candles") {
        this.chartType = "Spline";
      } else {
        this.chartType = "Candles";
      }
      this.init();
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    if (this.stream) this.stream.off();
  },
};
</script>

<style scoped>
#chartLarge {
  display: table;
  margin: 0 auto;
  width: 1000px;
}

.chartMenuItem {
  white-space: pre;
}

.p-inputswitch {
  top: 10px;
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #e6e6e6 !important;
  }
}
</style>