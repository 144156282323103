<template>
  <div id="coinData">
    Historical Coin Data
    <br/>
    <br/>
    <DataTable
      :value="coinData"
      :scrollable="true"
      :resizableColumns="true"
      columnResizeMode="fit"
      scrollHeight="500px"
      class="p-datatable-sm"
      ref="dt"
    >
      <Column field="property" header="Property" :sortable="true">
        <template #body="slotProps">
          <div>
            {{slotProps.data.property}}
          </div>
        </template>
      </Column>
      <Column field="value" header="Value">
        <template #body="slotProps">
          <div>
            {{slotProps.data.value}}
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "CoinData",
  components: {
    DataTable,
    Column,
  },
  props: ['coinName'],
  data() {
    return {
      coinData: [],
      currentPrice: 0.5,
      atlPrice: 0,
      athPrice: 1,
      low24: 0,
      high24: 1,
    }
  },
  methods: {
    async init() {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url =`${VUE_APP_AUTHENTICATED_API_URL}getCoinData`;
      const headers = { headers: { Authorization: token.jwtToken } };
      const body = JSON.stringify({ id: this.coinName });
      let result = undefined;
      await axios
        .post(url, body, headers)
        .then((response) => {
          result = response.data.data;
          // TODO: this should be formatted on the backend
          // to lighten the payload
          this.$emit('setCoinImageLink', result.image);
          const tableData = {
            'Coin': result.id,
            'Hashing Algorithm': result.hashing_algorithm,
            'Market Cap Rank': result.market_cap_rank,
            'All Time High': result.market_data.ath.usd,
            'All Time High Change %': result.market_data.ath_change_percentage.usd,
            'All Time High Date': result.market_data.ath_date.usd,
            'All Time Low': result.market_data.atl.usd,
            'All Time Low Change %': result.market_data.atl_change_percentage.usd,
            'All Time Low Date': result.market_data.atl_date.usd,
            'Circulating Supply': result.market_data.circulating_supply,
            'Total Volume': result.market_data.total_volume.usd,
            //'Current Price': result.market_data.current_price.usd,
            '24 Hour High': result.market_data.high_24h.usd,
            '24 Hour Low': result.market_data.low_24h.usd,
            '24 Hour Market Cap Change': result.market_data.market_cap_change_24h,
            '24 Hour Market Cap Change %': result.market_data.market_cap_change_percentage_24h,
            '24 Hour Price Change': result.market_data.price_change_24h,
            '1 Year Price Change %': result.market_data.price_change_percentage_1y,
            '7 Day Price Change %': result.market_data.price_change_percentage_7d,
            '14 Day Price Change %': result.market_data.price_change_percentage_14d,
            '24 Hour Price Change %': result.market_data.price_change_percentage_24h,
            '30 Day Price Change %': result.market_data.price_change_percentage_30d,
            '60 Day Price Change %': result.market_data.price_change_percentage_60d,
            '200 Day Price Change %': result.market_data.price_change_percentage_200d,
          }
          let formattedData = [];
          for (const [key, value] of Object.entries(tableData)) {
            formattedData.push({
              'property': key,
              'value': value,
            })
          }
          this.coinData = formattedData;

          // Set range data
          const rangeData = {
            low24:  tableData["24 Hour Low"],
            high24: tableData["24 Hour High"],
            lowAT: tableData["All Time Low"],
            highAT: tableData["All Time High"]
          }
          this.$emit('setRange', rangeData);
        })
        .catch((error) => {
          console.log(error);
        });
      return result;
    }
  },
  watch: { 
    coinName: function(/*newVal, oldVal*/) {
      this.init();
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped>
.p-datatable-sm {
  font-size: 12px;
}
</style>
