import { render, staticRenderFns } from "./CoinfeedTerms.vue?vue&type=template&id=7e7ace51&scoped=true&"
import script from "./CoinfeedTerms.vue?vue&type=script&lang=js&"
export * from "./CoinfeedTerms.vue?vue&type=script&lang=js&"
import style0 from "./CoinfeedTerms.vue?vue&type=style&index=0&id=7e7ace51&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e7ace51",
  null
  
)

export default component.exports