import Vue from 'vue';
import VueRouter from 'vue-router';
import router from './router'
import App from './App.vue'
import "@aws-amplify/ui-vue";
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import PrimeVue from 'primevue/config';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false

// Google Analytics
if (process.env.VUE_APP_DEPLOYMENT_ENVIRONMENT === 'production') {
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG },
    appName: 'coinfeed',
    pageTrackerScreenviewEnabled: true
  }, router);
}

Vue.use(VueRouter);
Vue.use(PrimeVue);

Amplify.configure(awsconfig);

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
