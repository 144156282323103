<template inline-template>
  <div id="gas">
    <Button
      id="gas"
      type="button"
      class="p-button-sm"
      @mouseenter="toggleGas"
      @mouseleave="toggleGas"
    >
      <img
        src="./../assets/gas-logo.png"
        alt="logo"
        height="15px"
        width="15px"
      />
    </Button>
    <OverlayPanel ref="gasPanel" id="gasOverlay">
      {{ gasPrice }}
    </OverlayPanel>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "EthGas",
  components: {
    Button,
    OverlayPanel,
  },
  data() {
    return {
      gasPrice: "0 Gwei",
      visible: false,
    };
  },
  methods: {
    async getGas() {
      // Get gas
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}readEthGasData`;
      const headers = { headers: { Authorization: token.jwtToken } };

      await axios
        .get(url, headers)
        .then((response) => {
          this.gasPrice = `${response.data.data.gas.result.ProposeGasPrice} Gwei`;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {});
    },
    toggleGas(event) {
      if (this.visible) {
        this.$refs.gasPanel.hide(event);
        this.visible = false;
      } else {
        this.getGas();
        this.$refs.gasPanel.show(event);
        this.visible = true;
      }
    },
  },
  mounted() {
    this.getGas();
  },
};
</script>

<style scoped>
#gas {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  width: 45px;
  display: inline;
}
#gas:hover {
  cursor: pointer;
}
#gasOverlay {
  border: 0px;
  padding: 0px;
  margin-top: 5px;
}
</style>