<template>
  <div id="flippening" @mouseenter="toggle" @mouseleave="toggle">
    <ol>
      <li id="btc">BTC</li>
      <li>/</li>
      <li id="eth">ETH</li>
    </ol>
    <OverlayPanel ref="op" id="searchOverlay">
      {{ratio}}
    </OverlayPanel>
  </div>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';

export default {
  name: "Flippening",
  props: ["coins"],
  components: {
    OverlayPanel,
  },
  data() {
    return {
      ratio: 0,
      visible: false,
      overlayContent: '',
    };
  },
  methods: {
    calc() {
      const BTC = this.coins[0].marketCap;
      const ETH = this.coins[1].marketCap;
      this.ratio = ETH / BTC;
    },
    toggle() {
      if(this.visible) {
        this.$refs.op.hide(event);
        this.visible = false;
      } else {
        this.calc();
        this.$refs.op.show(event);
        this.visible = true;
      }
    }
  },
  mounted() {
    this.calc();
  },
};
</script>

<style scoped>
#flippening {
  text-align: center;
  font-size: 10px;
}
#flippening:hover {
  cursor: pointer;
}
#btc {
  color: #f7931a;
}
#eth {
  color: #63688f;
}
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: decimal;
}
ol li {
  float: right;
  margin: 0px;
  padding: 0px;
}
li {
  list-style-type: none;
}
.p-overlaypanel {
  right: 0px;
  height: 22px;
  padding: 0px;
  margin: 0px;
  border: 0px;
}
</style>