<template>
  <div class="p-col">
    {{ title }}:
    <div id="rangeBar">
			<Slider v-model="price" :min="min" :max="max" :disabled="true"/>
		</div>
    Low: {{ min }} High: {{ max }} Current: {{ price }}
  </div>
</template>

<script>
import Slider from 'primevue/slider';

export default {
  name: "RangeVisualizer",
  components: {
		Slider
	},
  props: ["range", "coinPrice", "title"],
  data() {
    return {
      min: undefined,
      max: undefined,
			price: undefined,
    };
  },
  watch: {
    coinPrice: function (newVal /*, oldVal*/) {
			this.price = parseFloat(newVal);
      // Check if min or max needs to be updated
			if (this.price > this.max) {
        this.max = this.price;
      } else if (this.price < this.min) {
        this.min = this.price;
      }
    },
    range: function (newVal) {
      if (this.title === "24 Hour") {
        this.min = newVal.low24;
        this.max = newVal.high24;
      }
      if (this.title === "All Time") {
        this.min = newVal.lowAT;
        this.max = newVal.highAT;
      }
    },
  },
};
</script>

<style scoped>
#rangeBar {
  height: 5px;
  background: #e6e6e6;
	margin-top: 10px;
	margin-bottom: 10px;
}
</style>