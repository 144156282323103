<template>
  <Menubar :model="menuItems">
    <template #start>
      <a @click="setRoute('home')">
        <img src="./../assets/logo.svg" alt="logo" height="75px" width="75px" />
        <!-- <CoinFeed3D/> -->
      </a>
    </template>
    <template #item="{ item }">
      <router-link :to="item.label" replace />
    </template>
    <template #end>
      <InputText
        v-model="searchText" 
        id="coinSearch" 
        placeholder="Coin Search" 
        type="text" 
        class="p-inputtext-sm"
        autocomplete="off"
        v-on:keyup="searchCoins($event)"/>
      <OverlayPanel ref="op" id="searchOverlay">
        <ul id="searchResults">
          <li v-for="result in searchResults" :key="result.index">
            <p @click="setRoute(result.route)"> 
              <img :src="result.image" width="15" style="vertical-align: middle" />
              {{result.symbol}}
            </p>
          </li>
        </ul>
      </OverlayPanel>
      <EthGas />
      <Flippening :coins="coins" />
    </template>
  </Menubar>
</template>

  <script>
import { Auth } from "aws-amplify";
import Menubar from "primevue/menubar";
import InputText from "primevue/inputtext";
import OverlayPanel from 'primevue/overlaypanel';
import router from "./../router";
import EthGas from "./EthGas.vue";
import Flippening from "./Flippening.vue";
//import CoinFeed3D from "./branding/CoinFeed3D.vue";

const axios = require("axios");
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

export default {
  name: "NavBar",
  components: {
    Menubar,
    InputText,
    OverlayPanel,
    EthGas,
    Flippening,
    //CoinFeed3D,
  },
  props: ["coins"],
  data() {
    return {
      menuItems: [
        {
          label: "Coins",
          icon: "pi pi-fw pi-info-circle",
          to: "/coins",
        },
        {
          label: "News",
          icon: "pi pi-fw pi-globe",
          to: "/news",
        },
        /*
        {
          label: "Exchanges",
          icon: "pi pi-fw pi-sort-alt",
          to: "/exchanges",
        },
        {
          label: "Sentiment",
          icon: "pi pi-fw pi-users",
          to: "/sentiment",
        },
        {
          label: "Mining",
          icon: "pi pi-fw pi-shopping-cart",
          to: "/mining",
        },
        {
          label: "Staking",
          icon: "pi pi-fw pi-wallet",
          to: "/staking",
        },
        */
        {
          label: "Tools",
          icon: "pi pi-compass",
          items: [
            /*
            {
              label: "Charts",
              icon: "pi pi-chart-line",
              to: "/chart",
            },
            {
              label: "Historical Data",
              icon: "pi pi-calendar",
              to: "/historicalData",
            },
            {
              label: "Arbitrage Pairs",
              icon: "pi pi-chart-bar",
              to: "/arbitrage",
            },
            {
              label: "Whale Sightings",
              icon: "pi pi-eye",
              to: "/whales",
            },
            {
              label: "Discord",
              icon: "pi pi-discord",
              command: () => {
                window.open("https://discord.gg/NKkgSgrpfA", 'Coinfeed Discord Invite');
              }
            },
            */
            {
              label: "Settings",
              icon: "pi pi-cog",
              items: [
                {
                  label: "Customize",
                  icon: "pi pi-palette",
                  to: "/user/settings",
                },
                {
                  label: "Refresh",
                  icon: "pi pi-refresh",
                  command: () => {
                    window.location.reload();
                  }
                },
              ],
            },
          ],
        },
        /*
        {
          label: "Pro",
          icon: "pi pi-fw pi-user-plus",
          to: "/pro",
        },
        */
        {
          label: "Signout",
          icon: "pi pi-fw pi-sign-out",
          command: () => {
            this.$emit('signout');
          }
        },
      ],
      searchText: "",
      searchResults: [],
    };
  },
  methods: {
    init() {
      if (process.env.VUE_APP_DEPLOYMENT_ENVIRONMENT === "development") {
        this.addDebugger();
      }
    },
    setRoute(path) {
      router.push({ path: "/" + path }).catch(() => {
        "Cannot navigate to current page";
      });
      this.closeSearch()
    },
    searchCoins(event) {
      // If len of input is 0, hide
      if(this.searchText.length === 0){
        this.$refs.op.hide(event);
        this.searchResults = [];
        return;
      }
      var filter = this.searchText.toLowerCase();
      // Clear previous results
      this.searchResults = [];
      // Loop through all list items, hide those that don't match the search query
      let count = 0
      for (var i = 0; i < this.coins.length; i++) {
        if(this.coins[i] === undefined) {
          continue;
        }
        const symbol = `${this.coins[i].symbol} - ${this.coins[i].name}`;
        if (symbol.toLowerCase().indexOf(filter) > -1) {
          const routeSymbol = symbol.split(' ')[0].toLowerCase();
          this.searchResults.push(
            {
              index: count,
              symbol: symbol,
              image: this.coins[i].image,
              route: `coin/${routeSymbol}`
            }
          );
          count += 1
        } 
      }
      if(this.searchResults.length === 0){
        this.searchResults.push(
          {
            index: 0,
            symbol: 'No Results',
          }
        );
        return;
      }
      // If search panel is hidden show it
      this.$refs.op.show(event);
    },
    closeSearch() {
      this.$refs.op.hide(event);
      this.searchText = '';
    },
    async getGas() {
      // Get gas
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url = `${VUE_APP_AUTHENTICATED_API_URL}readEthGasData`;
      const headers = { headers: { Authorization: token.jwtToken } };

      await axios
        .get(url, headers)
        .then((response) => {
          this.gasPrice = `${response.data.data.gas.result.ProposeGasPrice} Gwei`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async toggleGas(event) {
      this.getGas();
      this.$refs.gasPanel.show(event);
    },
    addDebugger() {
      for (let i = 0; i < this.menuItems.length; i++) {
        if (this.menuItems[i].label === "Tools") {
          for (let j = 0; j < this.menuItems[i].items.length; j++) {
            if (this.menuItems[i].items[j].label === "Settings") {
              this.menuItems[i].items[j].items.push({
                label: "Debugger",
                icon: "pi pi-question-circle",
                to: "debug",
              });
            }
          }
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
#searchOverlay {
  width: 260px;
  max-height: 250px;
  overflow-y: auto;
  text-align: left;
}
#flippening {
  margin-top: 5px;
}
a {
  text-decoration: none !important;
  color: #e6e6e6;
}
li:hover {
  background-color: #272727 !important;
  cursor: pointer;
}
.p-menubar {
  height: 75px !important;
}
</style>
