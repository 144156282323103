<template>
  <div>
    <div id="parent">
      <table>
        <thead id="colHeaders">
          <tr>
            <th
              v-for="(value, i) in headerValues"
              :key="value + i"
              v-on:click="sortColumn(i)"
            >
              {{ value }}
              <i class="pi pi-sort-alt" style="font-size: 0.75rem"></i>&nbsp;
            </th>
          </tr>
        </thead>
        <tbody id="mybody" @scroll="fixscroll">
          <tr v-for="(value, i) in rowCache" :key="value + i" :style="`color: ${colorCache[i]}`">
            <td v-for="(item, j) in value" :key="value + j">
              <div v-if="j === 0" id="fixedCol" @click="goToCoin(item[1])">
                <div class="p-grid">
                  <div class="p-col-4">
                    <img
                      :alt="value"
                      :src="item[2]"
                      width="24"
                    />
                  </div>
                  <div class="p-col-8s"  id="coinLabel">
                    {{ item[0] }}
                    <br>
                    <Chip id="chip">
                      {{ item[1] }}
                      #{{ item[3] }}
                    </Chip>
                  </div>
                </div>
              </div>
              <!-- Format columns -->
              <!-- Price -->
              <div v-if="j === 1">
                ${{ numberWithCommas(item[0]/*, true*/) }}
              </div>
              <!-- Others -->
              <div v-if="j !== 0 && j !== 1">
                {{ numberWithCommas(item[0]/*, true*/) }}
              </div>
            </td>
          </tr>
        </tbody>

        <tbody></tbody>
      </table>
    </div>
  </div>
</template>

<script>
import router from "../router";
import numberHelpers from "../utilities/numberHelpers";
import Chip from 'primevue/chip';

export default {
  name: "CoinFlexTable",
  components: {
    Chip
  },
  props: ["headerValues", "rowValues", "rowColors", "heightPercentage"],
	watch: {
		rowValues: function(newVal) {
			this.rowCache = newVal;
    },
		rowColors: function(newVal) {
			this.colorCache = newVal;
		}
	},
  data() {
    return {
			rowCache: [],
			colorCache: [],
			lastSortCol: 6,
			flipped: false,
		};
  },
  methods: {
    init(){
      const parent = document.getElementById("parent");
      parent.style.height = this.heightPercentage;
    },
    fixscroll() {
      const thead = document.getElementById("colHeaders");
      const tbodyScroll = document.getElementById("mybody").scrollLeft;
      thead.scrollLeft = tbodyScroll;
    },
    sortColumn(colIndex) {
			if(this.lastSortCol === colIndex) {
				this.flipped = !this.flipped;
			} else {
				this.lastSortCol = colIndex;
			}

      this.rowValues = this.rowValues.sort(function (a, b) {
        if (colIndex === 0) {
					// Sort by the given col alphabetically
          if (a[0][0].toLowerCase() > b[0][0].toLowerCase()) {
            return -1;
          }
          if (a[0][0].toLowerCase() < b[0][0].toLowerCase()) {
            return 1;
          }
          return 0;
        } else {
          // Sort by the given col numerically
					const c = parseFloat(a[colIndex]);
					const d = parseFloat(b[colIndex]);
          if (c < d) {
            return -1;
          }
          if (c > d) {
            return 1;
          }
          return 0;
        }
      });
      if (this.flipped) {
        this.rowValues = this.rowValues.reverse();
      }

			const newKeys = [];
			for(let i = 0; i < this.rowValues.length; i++){
				newKeys.push(this.rowValues[i][0][0]);
			}

			this.$emit('updateSortKeys', newKeys);
    },
    goToCoin(symbol) {
      router.push({ path: `/coin/${symbol}` }).catch(() => {
        "Cannot navigate to current page";
      });
    },
    numberWithCommas: numberHelpers.numberWithCommas,
  },
  mounted(){
    this.init();
  }
};
</script>

<style scoped>
#chip {
  font-size: 8px;
}

#parent {
  position: relative;
  left: 0vw;
  width: 100vw;
  height: 50vh;
  overflow: hidden;
	color:#e6e6e6;
  font-size: 12px;
}

#fixedCol:hover {
  cursor: pointer;
  outline: 1px solid rgb(100, 181, 246);
}

#coinLabel {
  padding-top: 10px;
  text-align: left;
}

table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  border: 1px solid #383838;
  border-collapse: collapse;
  overflow: hidden;
  /* Use this to create a "dead" area color if table is too wide for cells */
  background-color: #1e1e1e;
}

thead {
  /*
  Grow thead automatically to fit content, don't shrink it
  proportionately to the body.
  */
  flex: 1 0 auto;
  display: block;
  /* x-scrolling will be managed via JS */
  overflow-x: hidden;
  /*
  Keep header columns aligned with useless scrollbar.
  For IE11, use "dead area" color to hide scrollbar functions
  */
  overflow-y: scroll;
  scrollbar-base-color: #ccc;
  scrollbar-face-color: #ccc;
  scrollbar-highlight-color: #ccc;
  scrollbar-track-color: #ccc;
  scrollbar-arrow-color: #ccc;
  scrollbar-shadow-color: #ccc;
  scrollbar-dark-shadow-color: #ccc;
}

th:hover {
  cursor: pointer;
  outline: 1px solid rgb(100, 181, 246);
}

/*
For Webkit, use "dead area" color to hide scrollbar functions
TODO: on Chrome/Safari for Mac, scrollbars are not shown anyway and
this creates an extra block. No impact on iOS Safari.
*/
thead::-webkit-scrollbar {
  display: block;
  background-color: #ccc;
}
thead::-webkit-scrollbar-track {
  background-color: #1e1e1e;
}

/* Scroll the actual tbody (second child on all browsers) */
tbody {
  padding-top: 5px;
  display: block;
  overflow: scroll;
}

/* IE11 adds an extra tbody, have to hide it */
tbody:nth-child(3) {
  display: none;
}

/* The one caveat, a hard-set width is required. */
td,
th {
  width: 150px;
  min-width: 150px;
  padding: 0.3em;
  border: 1px solid rgba(56, 56, 56, 0.25);
  background-color: #1e1e1e;
}

th {
  background-color: #1e1e1e;
  padding-bottom: 4px;
  font-weight: normal;
}

td:first-child,
th:first-child {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  text-align: center;
}
</style>