<template>
  <div>
		Live Coin Data
		<br>
		<br>
    <DataTable
      id="liveTable"
      :value="liveData"
      :scrollable="true"
      scrollDirection="both"
      scrollHeight="300px"
      class="p-datatable-sm"
      ref="dt"
    >

			<Column field="s" header="Symbol" headerStyle="width: 125px">
        <template #body="slotProps">
          <div>
            {{slotProps.data.s}}
          </div>
        </template>
      </Column>
			<Column field="c" header="Price" headerStyle="width: 125px">
        <template #body="slotProps">
          <div>
            {{slotProps.data.c}}
          </div>
        </template>
      </Column>
			<Column field="p" header="Price Change" headerStyle="width: 125px">
        <template #body="slotProps">
          <div>
            {{slotProps.data.p}}
          </div>
        </template>
      </Column>
			<Column field="P" header="Price Change %" headerStyle="width: 125px">
        <template #body="slotProps">
          <div>
            {{slotProps.data.P}}
          </div>
        </template>
      </Column>
			<Column field="w" header="Weighted Avg Price" headerStyle="width: 125px">
        <template #body="slotProps">
          <div>
            {{slotProps.data.w}}
          </div>
        </template>
      </Column>
			<Column field="v" header="Volume" headerStyle="width: 125px">
        <template #body="slotProps">
          <div>
            {{slotProps.data.v}}
          </div>
        </template>
      </Column>

      <!--
      e // Event type
      E // Event time
      s // Symbol
      p // Price change
      P // Price change percent
      w // Weighted average price
      x // First trade(F)-1 price (first trade before the 24hr rolling window)
      c // Last price
      Q // Last quantity
      b // Best bid price
      B // Best bid quantity
      a // Best ask price
      A // Best ask quantity
      o // Open price
      h // High price
      l // Low price
      v // Total traded base asset volume
      q // Total traded quote asset volume
      O // Statistics open time
      C // Statistics close time
      F // First trade ID
      L // Last trade Id
      n // Total number of trades
      -->

		</DataTable>
	</div>
</template>

<script>
import Stream from './../utilities/tvjs-stream.js';
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
  name: "PriceTicker",
	props: ['coin'],
  components: {
		DataTable,
    Column,
	},
  data() {
    return {
			liveData: undefined,
		};
  },
  methods: {
    init() {
      this.stream = new Stream(
        `wss://stream.binance.com:9443/ws/${this.coin.toLowerCase()}usdt@ticker`
      );
      this.stream.ontrades = this.on_trades;
    },
		on_trades(data) {
			this.liveData = [data];
      this.$emit('setPrice', data.c);
		},
  },
  watch: {
      coin: function(/*newVal, oldVal*/) {
        if (this.stream) this.stream.off();
        this.init();
    },
  },
  mounted() {
    this.init();
  },
	beforeDestroy() {
    if (this.stream) this.stream.off()
  }
};
</script>
