<template>
  <div>
    <div id="News">
      <div v-for="story in stories" :key="story.title" id="Story">
        <!-- <i class="pi pi-globe" style="font-size: 0.5rem"></i>&nbsp; -->
        <img :src="`https://www.google.com/s2/favicons?domain=${story.link}`" width="12px" style="vertical-align: middle" />&nbsp;
        <a :href="story.link" target="_blank" rel="noopener noreferrer">{{
          story.title
        }}</a>
        <Divider />
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Divider from "primevue/divider";
const VUE_APP_AUTHENTICATED_API_URL = process.env.VUE_APP_AUTHENTICATED_API_URL;

const axios = require("axios");

export default {
  name: "FrontNews",
  components: {
    Divider,
  },
  data() {
    return {
      stories: [],
    };
  },
  methods: {
    async init() {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken;
      const url =`${VUE_APP_AUTHENTICATED_API_URL}readNewsFeed`;
      const headers = { headers: { Authorization: token.jwtToken } };
      await axios
        .get(url, headers)
        .then((response) => {
          this.stories = response.data.data.feed;
        })
        .catch((error) => {
          console.log(error);
        });
      // Limit number of stories displayed
      this.stories = this.stories.slice(0, 15);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
#News {
  text-align: left;
  background: #1e1e1e;
  font-size: 12px;
}
#Story:hover {
  background: #272727;
}
a {
  text-decoration: none !important;
  color: #e6e6e6;
}
a:hover {
  background: #272727;
}
.p-divider-solid.p-divider-horizontal:before {
  border-top-style: solid !important;
  padding: 0px;
}
.p-divider-solid.p-divider-horizontal {
  margin: 0px;
}
</style>
