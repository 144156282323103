import FrontPage from './FrontPage'
import Coin from './Coin'
import Coins from './Coins'
import News from './News'
import UserSettings from './UserSettings'
import Debugger from './Debugger'
import CoinMap from './TreeMap'

// Components that are mounted in router view
export default {
    FrontPage,
    //Template,
    Coin,
    //Sentiment,
    //Mining,
    Coins,
    //Exchanges,
    //Arbitrage,
    News,
    //HistoricalData,
    //Chart,
    UserSettings,
    //Whales,
    Debugger,
    CoinMap,
}